svgline.prototype.number=0; //To increment when new lines are added and to use as ID
svgline.prototype.lastUpdate=0; //To store timestamp of last update event so we don't run them multiple times per  millisecond
svgline.prototype.spreadDistance=13;
svgline.prototype.spreadPadding=5;
svgline.prototype.arrowDist=4;

svgline.prototype.updateLinesSpread=function(){
	//Remember a set of values we can compare in the end to see if it changed
	if(Array.isArray(window.svglines)){
		window.svglines.forEach(function(svgline){
			if(!svgline.removed && !svgline.svg.hasClass('hide')){
				svgline.compare=[svgline.start.x,svgline.start.y,svgline.end.x,svgline.end.y];
			}
		});
	}
	//Create a list of connected elements each with a list of lines connected to each side of the element
	var nodes=[];
	if(Array.isArray(window.svglines)){
		window.svglines.forEach(function(svgline){
			if(!svgline.removed && !svgline.svg.hasClass('hide')){
				//Look up nodes for 'from' and 'to' elements
				let nodeFrom=nodes.find(function(item){return item.el==svgline.from;});
				let nodeTo=nodes.find(function(item){return item.el==svgline.to;});
				//Create them if not found
				if(nodeFrom==undefined){
					nodeFrom={
						el:svgline.from,
						rect:svgline.getRect(svgline.from),
						lines:{up:[],right:[],down:[],left:[]}
					};
					nodes.push(nodeFrom);
				}
				if(nodeTo==undefined){
					nodeTo={
						el:svgline.to,
						rect:svgline.getRect(svgline.to),
						lines:{up:[],right:[],down:[],left:[]}
					};
					nodes.push(nodeTo);
				}
				//Gets shortest way to connect two node edges
				svgline.getLineDirections(nodeFrom.rect,nodeTo.rect);
				//Add line to the corresponding edges of 'from' and 'to' nodes
				if(nodeFrom.el==svgline.from) nodeFrom.lines[svgline.start.dir].push(svgline);
				if(nodeTo.el==svgline.to) nodeTo.lines[svgline.end.dir].push(svgline);
			}
		});
	}
	//Now when we have elements and lines connected to each side, we spread the lines apart for readability
	nodes.forEach(function(node){
		node.spacing={up:0,right:0,down:0,left:0};
		node.points={up:[],right:[],down:[],left:[]};
		for(const [dir,lines] of Object.entries(node.lines)){
			//First calculate spacing between lines
			var dimension=((dir=='up' || dir=='down')?node.rect.width:node.rect.height)-(svgline.prototype.spreadPadding*2);
			node.spacing[dir]=Math.min(svgline.prototype.spreadDistance,dimension/lines.length);
			//Now calculate positions for every point on the edge
			lines.forEach(function(line,i){
				var point={x:0,y:0};
				//Calculate position on x or y axis based on edge
				let position=((dir=='up' || dir=='down')?node.rect.centerx:node.rect.centery)-((node.spacing[dir]*(lines.length-1))/2)+(node.spacing[dir]*i);
				//Set x and y of the point
				if(dir=='up'){
					point.x=position;
					point.y=node.rect.centery-node.rect.hh;
				}else if(dir=='right'){
					point.x=node.rect.centerx+node.rect.hw;
					point.y=position;
				}else if(dir=='down'){
					point.x=position;
					point.y=node.rect.centery+node.rect.hh;
				}else if(dir=='left'){
					point.x=node.rect.centerx-node.rect.hw;
					point.y=position;
				}
				node.points[dir].push(point);
			});
		}
		//Now that we have points on each edge, iterate through edges again
		for(const [dir,points] of Object.entries(node.points)){
			//Sort lines in a different order based on where their other end is and direction of current edge
			node.lines[dir].sort(function(a,b){
				const aPoint=(a.from!=node.el?a.start:a.end);
				const bPoint=(b.from!=node.el?b.start:b.end);
				if(dir=='up') return (aPoint.x>bPoint.x?1:-1);
				if(dir=='right') return (aPoint.y>bPoint.y?1:-1);
				if(dir=='down') return (aPoint.x>bPoint.x?1:-1);
				if(dir=='left') return (aPoint.y>bPoint.y?1:-1);
			});
			points.forEach(function(point,i){
				node.lines[dir][i].updatePoint((node.el==node.lines[dir][i].from?'start':'end'),point.x,point.y);
			});
		}
	});

	//Iterate through lines and update the ones that moved
	if(Array.isArray(window.svglines)){
		window.svglines.forEach(function(svgline){
			if(!svgline.removed && !svgline.svg.hasClass('hide')){
				let compare=[svgline.start.x,svgline.start.y,svgline.end.x,svgline.end.y];
				let update=false;
				svgline.compare.forEach((item,i)=>{update=(compare[i]!=item?true:update);});
				if(update || svgline.forceUpdate){
					svgline.update(true);
					svgline.forceUpdate=undefined;
				}
			}
		});
	}
}

export default function svgline(from,to,settings){
	
	this.startArrow=false;
	this.endArrow=false;
	this.color=undefined;
	this.dash=false;
	this.from=from; //origin element
	this.to=to; //destination element
	this.start={x:0,y:0,dir:''}; //start of the line
	this.end={x:0,y:0,dir:''}; //end of the line
	this.svg; //root svg element
	this.path; //path element in svg
	this.prefix="svgline-"+(++svgline.prototype.number); //prefix unique for this instance
	this.distance=0; //distance between start and end points
	this.removed=false;
	this.parent=document.body; //dom element that will hold the svg images

	if(settings!=undefined){
		if(settings.startArrow!==undefined) this.startArrow=settings.startArrow;
		if(settings.endArrow!==undefined) this.endArrow=settings.endArrow;
		if(settings.color!==undefined) this.color=settings.color;
		if(settings.dash!==undefined) this.dash=settings.dash;
		if(settings.parent!==undefined) this.parent=settings.parent;
		if(settings.description!==undefined) this.description=settings.description;
		if(settings.interactive!==undefined) this.interactive=settings.interactive;
	}

	this.emake=function(tag,attributes,html){ //Create element in SVG namespace
		var e=document.createElementNS('http://www.w3.org/2000/svg',tag);
		for(var k in attributes) e.setAttribute(k,attributes[k]);
		if(html!=undefined) e.innerHTML=html;
		return e;
	}

	this.xmake=function(tag,attributes,html){ //Create element in XHTML namespace
		var e=document.createElementNS('http://www.w3.org/1999/xhtml',tag);
		for(var k in attributes) e.setAttribute(k,attributes[k]);
		if(html!=undefined) e.innerHTML=html;
		return e;
	}

	this.eupdate=function(e,attributes){ //Update element's parameters
		for(var k in attributes){
			if(attributes[k]==null) e.removeAttribute(k);
			else e.setAttribute(k,attributes[k]);
		}
		return e;
	}

	this.create=function(){
		//Create SVG root element
		this.svg=this.emake('svg',{id:this.prefix,'class':'mapArrow','xmlns':'http://www.w3.org/2000/svg','version':'1.1'});
		//Create marker
		var defs=this.emake('defs');
		
		//var marker=this.emake('marker',{id:this.prefix+'-marker',markerWidth:10,markerHeight:12,refX:6,refY:6,orient:'auto-start-reverse',markerUnits:'userSpaceOnUse'});
		//marker.appendChild(this.emake('path',{d:'M0,0 L0,12 L10,6 z',style:'fill:'+this.color+' !important;stroke-width:0 !important;'}));
		var marker=this.emake('marker',{id:this.prefix+'-marker',markerWidth:8,markerHeight:12,refX:4,refY:6,orient:'auto-start-reverse',markerUnits:'userSpaceOnUse'});
		marker.appendChild(this.emake('path',{d:'M2,0 L8,6 L2,12 L0,10 L4,6 L0,2 z',style:'stroke-width:0 !important;'}));

		defs.appendChild(marker);
		this.svg.appendChild(defs);
		//Create the line for mouse interactions
		if(this.interactive){
			this.pathHover=this.emake('path',{id:this.prefix+'-path-hover',class:'pathHover'});
			this.pathHover.addEventListener("mouseover",e=>{if(this.mouseover!=undefined) this.mouseover(e,this);});
			this.pathHover.addEventListener("mouseout",e=>{if(this.mouseout!=undefined) this.mouseout(e,this);});
			this.svg.appendChild(this.pathHover);
		}
		//Create main line
		this.path=this.emake('path',{id:this.prefix+'-path',class:'path',style:'fill:none;pointer-events:none;'});
		this.svg.appendChild(this.path);
		//Append svg to body
		this.parent.appendChild(this.svg);
		//Keep references in a global array
		if(window.svglines==undefined) window.svglines=[];
		window.svglines.push(this);
	}

	this.update=function(skipGettingLineEnds){
		if(skipGettingLineEnds!=true) this.getLineEnds();
		//Get point in the center of the line. It also contains the angle in case we need to rotate the object
		this.center=this.getPoint(0.5);
		//Define path
		if(this.shape=='straight'){ //Straight line
			var d='M'+this.start.x+' '+this.start.y+', '+this.end.x+' '+this.end.y;
		}else if(this.shape=='elbow'){ //Elbow line
			//Now create an elbow line
			var d='M'+this.start.x+' '+this.start.y;
			//From one side to another side
			if((this.start.dir=='right' || this.start.dir=='left') && (this.end.dir=='right' || this.end.dir=='left')){
				d+=' L'+this.center.x+' '+this.start.y;
				d+=' L'+this.center.x+' '+this.end.y;
			//From one top or bottom to another
			}else if((this.start.dir=='up' || this.start.dir=='down') && (this.end.dir=='up' || this.end.dir=='down')){
				d+=' L'+this.start.x+' '+this.center.y;
				d+=' L'+this.end.x+' '+this.center.y;
			//From side to top or bottom
			}else if((this.start.dir=='right' || this.start.dir=='left') && (this.end.dir=='up' || this.end.dir=='down')){
				d+=' L'+this.end.x+' '+this.start.y;
				this.center.x=this.end.x;
			//From top or bottom to side
			}else if((this.start.dir=='up' || this.start.dir=='down') && (this.end.dir=='left' || this.end.dir=='right')){
				d+=' L'+this.start.x+' '+this.end.y;
				this.center.x=this.start.x;
			}
			d+=' L'+this.end.x+' '+this.end.y;
		}else{ //Regular curve
			var d='M'+this.start.x+' '+this.start.y+' C'+this.start.hx+' '+this.start.hy+', '+this.end.hx+' '+this.end.hy+', '+this.end.x+' '+this.end.y;
		}
		//Update path. hx and hy are bezier handle coordinates in case you've forgotten
		var props={
			'd':d,
			'marker-start':(this.startArrow?'url(#'+this.prefix+'-marker)':null),
			'marker-end':(this.endArrow?'url(#'+this.prefix+'-marker)':null)
		}
		//Update the line for mouse interactions
		if(this.interactive){
			this.eupdate(this.pathHover,{d:props.d});
		}
		if(this.dash) props['stroke-dasharray']='4,3';
		//Update the main line
		this.eupdate(this.path,props);
		if(this.color) this.path.style.stroke=this.color;
		//Get bounding box of the whole svg and add margins to it
		let extend=10;
		var box=this.svg.getBBox();
		box.x-=extend;
		box.width+=extend*2;
		box.y-=extend;
		box.height+=extend*2;
		//Set viewbox and position based on that baunding box
		this.eupdate(this.svg,{
			'viewBox':box.x+' '+box.y+' '+box.width+' '+box.height,
			'style':'position:absolute;left:'+box.x+'px;top:'+box.y+'px;width:'+box.width+'px;height:'+box.height+'px;'
		});
		//Call custom onUpdate fucntuion
		if(this.onUpdate!=undefined) this.onUpdate(this);
	}

	this.remove=function(){
		this.svg.parentNode.removeChild(this.svg);
		this.removed=true;
	}

	this.getRect=function(e){ //Get elements bounding rectangle including document offset
		var
			found,
			left=0,
			top=0,
			width=0,
			height=0,
			offsetBase=this.getRect.offsetBase,
			transform={scaleX:1,scaleY:1,left:0,top:0};
		if(!offsetBase && document.body){ //When running first time, we create an empty div at 0,0 of the parent
			offsetBase=this.getRect.offsetBase=document.createElement('div');
			offsetBase.style.cssText='position:absolute;left:0;top:0';
			this.parent.appendChild(offsetBase);
		}
		if(e && e.ownerDocument===document && 'getBoundingClientRect' in e && offsetBase){
			found=true;
			var boundingRect=e.getBoundingClientRect();
			var baseRect=offsetBase.getBoundingClientRect();
			transform=this.getTransform(this.parent); //In case if parent is scaled by CSS transform
			left=boundingRect.left-baseRect.left;
			top=boundingRect.top-baseRect.top;
			width=boundingRect.right-boundingRect.left;
			height=boundingRect.bottom-boundingRect.top;
			left/=transform.scaleX;
			width/=transform.scaleX;
			top/=transform.scaleY;
			height/=transform.scaleY;
		}
		var hw=width/2;
		var hh=height/2;
		return {
			found:found,
			left:left,
			top:top,
			width:width,
			height:height,
			right:left+width,
			bottom:top+height,
			centerx:left+hw,
			centery:top+hh,
			hw:hw,
			hh:hh
		};
	}
	//Get css transformation matrix and parse scale and position parameters out of it
	this.getTransform=function(el){
		var result={scaleX:1,scaleY:1,left:0,top:0};
		var matrix=window.getComputedStyle(el).transform;
		if(matrix.startsWith('matrix3d(')){
			data=matrix.slice(9,-1).split(', ');
			result.scaleX=parseFloat(data[0]);
			result.scaleY=parseFloat(data[5]);
			result.left=parseFloat(data[1]);
			result.top=parseFloat(data[2]);
		}else if(matrix.startsWith('matrix(')){
			data=matrix.slice(7,-1).split(', ');
			result.scaleX=parseFloat(data[0]);
			result.scaleY=parseFloat(data[3]);
			result.left=parseFloat(data[4]);
			result.top=parseFloat(data[5]);
		}
		return result;
	}

	this.getLineDirections=function(fromRect,toRect){  //Based on elements' size and position get best points to start and end the line
		var fromPoints=[];
		var toPoints=[];
		//Add all possible horisontal connections
		if(fromRect.centerx<toRect.centerx){
			fromPoints.push({x:fromRect.centerx+fromRect.hw,y:fromRect.centery,dir:'right'});
			toPoints.push({x:toRect.centerx-toRect.hw,y:toRect.centery,dir:'left'});
		}else{
			fromPoints.push({x:fromRect.centerx-fromRect.hw,y:fromRect.centery,dir:'left'});
			toPoints.push({x:toRect.centerx+toRect.hw,y:toRect.centery,dir:'right'});
		}
		//Add all possible vertical connections
		if(fromRect.centery<toRect.centery){
			fromPoints.push({x:fromRect.centerx,y:fromRect.centery+fromRect.hh,dir:'down'});
			toPoints.push({x:toRect.centerx,y:toRect.centery-toRect.hh,dir:'up'});
		}else{
			fromPoints.push({x:fromRect.centerx,y:fromRect.centery-fromRect.hh,dir:'up'});
			toPoints.push({x:toRect.centerx,y:toRect.centery+toRect.hh,dir:'down'});
		}
		//Get the shortest distance
		this.distance=false;
		for(var fp in fromPoints){
			for(var tp in toPoints){
				var ndist=this.getDistanceSquared(fromPoints[fp].x,fromPoints[fp].y,toPoints[tp].x,toPoints[tp].y);
				if(this.distance===false || ndist<this.distance){
					this.start=fromPoints[fp];
					this.end=toPoints[tp];
					this.distance=ndist;
				}
			}
		}
	}

	this.getLineEnds=function(){ //Based on elements' size and position get best points to start and end the line
		var fromRect=this.getRect(this.from);
		var toRect=this.getRect(this.to);
		var fromPoints=[];
		var toPoints=[];
		//Add all possible horisontal connections
		if(fromRect.centerx<toRect.centerx){
			fromPoints.push({x:fromRect.centerx+fromRect.hw,y:fromRect.centery,dir:'right'});
			toPoints.push({x:toRect.centerx-toRect.hw,y:toRect.centery,dir:'left'});
		}else{
			fromPoints.push({x:fromRect.centerx-fromRect.hw,y:fromRect.centery,dir:'left'});
			toPoints.push({x:toRect.centerx+toRect.hw,y:toRect.centery,dir:'right'});
		}
		//Add all possible vertical connections
		if(fromRect.centery<toRect.centery){
			fromPoints.push({x:fromRect.centerx,y:fromRect.centery+fromRect.hh,dir:'down'});
			toPoints.push({x:toRect.centerx,y:toRect.centery-toRect.hh,dir:'up'});
		}else{
			fromPoints.push({x:fromRect.centerx,y:fromRect.centery-fromRect.hh,dir:'up'});
			toPoints.push({x:toRect.centerx,y:toRect.centery+toRect.hh,dir:'down'});
		}
		//Get the shortest distance
		this.distance=false;
		for(var fp in fromPoints){
			for(var tp in toPoints){
				var ndist=this.getDistance(fromPoints[fp].x,fromPoints[fp].y,toPoints[tp].x,toPoints[tp].y);
				if(this.distance===false || ndist<this.distance){
					this.start=fromPoints[fp];
					this.end=toPoints[tp];
					this.distance=ndist;
				}
			}
		}
		this.pushBezierStartAndEnd(this.distance/2.5);
	}

	//Updates start or end x and y
	this.updatePoint=function(type,x,y){
		if(type=='start' || type=='end'){
			this[type].x=x;
			this[type].y=y;
			this.pushBezierStartAndEnd(this.getDistance(this.start.x,this.start.y,this.end.x,this.end.y)/2.5,type);
		}
	}

	//Sets bezier points and pads for arrows
	this.pushBezierStartAndEnd=function(push,padOnly){
		this.start.hx=this.start.x;
		this.start.hy=this.start.y;
		if(this.start.dir=='right') this.start.hx+=push;
		if(this.start.dir=='left') this.start.hx-=push;
		if(this.start.dir=='down') this.start.hy+=push;
		if(this.start.dir=='up') this.start.hy-=push;
		this.end.hx=this.end.x;
		this.end.hy=this.end.y;
		if(this.end.dir=='right') this.end.hx+=push;
		if(this.end.dir=='left') this.end.hx-=push;
		if(this.end.dir=='down') this.end.hy+=push;
		if(this.end.dir=='up') this.end.hy-=push;
		//Add padding in case of arrows
		if(this.startArrow && (padOnly!=undefined && padOnly=='start')){
			if(this.start.dir=='right') this.start.x+=this.arrowDist;
			if(this.start.dir=='left') this.start.x-=this.arrowDist;
			if(this.start.dir=='down') this.start.y+=this.arrowDist;
			if(this.start.dir=='up') this.start.y-=this.arrowDist;
		}
		if(this.endArrow && (padOnly!=undefined && padOnly=='end')){
			if(this.end.dir=='right') this.end.x+=this.arrowDist;
			if(this.end.dir=='left') this.end.x-=this.arrowDist;
			if(this.end.dir=='down') this.end.y+=this.arrowDist;
			if(this.end.dir=='up') this.end.y-=this.arrowDist;
		}
	}

	this.getDistance=function(x1,y1,x2,y2){ //Get distance between two points
		var dx=x2-x1;
		var dy=y2-y1;
		return Math.sqrt((dx*dx)+(dy*dy));
	}

	this.getDistanceSquared=function(x1,y1,x2,y2){ //Get distance between two points
		var dx=x2-x1;
		var dy=y2-y1;
		return (dx*dx)+(dy*dy);
	}

	this.getPoint=function(t){
		if(this.shape=='straight' || this.shape=='elbow'){
			return this.getPointOnCubic({x:this.start.x,y:this.start.y},{x:this.start.x,y:this.start.y},{x:this.end.x,y:this.end.y},{x:this.end.x,y:this.end.y},t);
		}else{ //Bezier
			return this.getPointOnCubic({x:this.start.x,y:this.start.y},{x:this.start.hx,y:this.start.hy},{x:this.end.hx,y:this.end.hy},{x:this.end.x,y:this.end.y},t);
		}
	}
	this.getPointOnCubic=function(p0,p1,p2,p3,t){
		var
		t2=t*t,
		t3=t2*t,
		t1=1-t,
		t12=t1*t1,
		t13=t12*t1,
		x=t13*p0.x+3*t12*t*p1.x+3*t1*t2*p2.x+t3*p3.x,
		y=t13*p0.y+3*t12*t*p1.y+3*t1*t2*p2.y+t3*p3.y,
		mx=p0.x+2*t*(p1.x-p0.x)+t2*(p2.x-2*p1.x+p0.x),
		my=p0.y+2*t*(p1.y-p0.y)+t2*(p2.y-2*p1.y+p0.y),
		nx=p1.x+2*t*(p2.x-p1.x)+t2*(p3.x-2*p2.x+p1.x),
		ny=p1.y+2*t*(p2.y-p1.y)+t2*(p3.y-2*p2.y+p1.y),
		ax=t1*p0.x+t*p1.x,
		ay=t1*p0.y+t*p1.y,
		cx=t1*p2.x+t*p3.x,
		cy=t1*p2.y+t*p3.y,
		angle=(90-Math.atan2(mx-nx,my-ny)*180/ Math.PI);
		angle+=angle>180?-180:180;
		return{
			x:x,
			y:y,
			fromP2: {x: mx, y: my},
			toP1:   {x: nx, y: ny},
			fromP1: {x: ax, y: ay},
			toP2:   {x: cx, y: cy},
			angle:  angle
		};
	}

	this.create();
	this.update();
};

/*
window.addEventListener('resize',function(){
	if(window.svglineUpdateTimeout) clearTimeout(window.svglineUpdateTimeout);
	window.svglineUpdateTimeout=setTimeout(function(){window.dispatchEvent(new Event('svglinesUpdate'));},10);
});
*/

//Dispatch this event from anywhere if you need to reposition the lines
window.addEventListener('svglinesUpdate',function(){
	if(window.svglines!=undefined && window.svglines.length>0 && Date.now()-svgline.prototype.lastUpdate>500){
		svgline.prototype.lastUpdate=Date.now();
		console.time('svglinesUpdate');
		svgline.prototype.updateLinesSpread();
		console.timeEnd('svglinesUpdate');
	}
});