/*
    Some useful functions to work with DOM without jQuery
*/
import svgline from './svgline';

Element.prototype.hasClass=function(className){
    var elClass=this.getAttribute('class');
    if(elClass==undefined) return false;
    return (' '+elClass+' ').indexOf(' '+className+' ')>-1;
}

Element.prototype.addClass=function(className){
    var elClass=this.getAttribute('class');
    if(elClass==undefined) this.setAttribute("class",className);
    else if((' '+elClass+' ').indexOf(' '+className+' ')==-1){
        this.setAttribute("class",(elClass+" "+className).trim());
    }
}

Element.prototype.removeClass=function(className){
    var elClass=this.getAttribute('class');
    if(elClass==undefined) return false;
    else if((' '+elClass+' ').indexOf(' '+className+' ')>-1){
        this.setAttribute("class",elClass.replace(new RegExp('(\\s|^)'+className+'(\\s|$)'),' '))
    }
}

Element.prototype.toggleClass=function(className){
    if(this.hasClass(className)) this.removeClass(className);
    else this.addClass(className);
}

if(!Element.prototype.matches) {
    Element.prototype.matches=Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if(!Element.prototype.closest){
    Element.prototype.closest=function(s){
        var el=this;
        do{
            if(Element.prototype.matches.call(el,s)) return el;
            el=el.parentElement || el.parentNode;
        }while(el!==null && el.nodeType===1);
        return null;
    };
}

Document.prototype.val=function(selector,val){
    var inputs=document.querySelectorAll(selector);
    if(inputs!=null) inputs.forEach(input=>input.value=val);
}

Document.prototype.bind=function(selector,event,callback){
    var elements=document.querySelectorAll(selector);
    if(elements!=null){
        const events=event.split(' ');
        elements.forEach(function(element){
            events.forEach(function(event){
                element.addEventListener(event,callback);
            })
        });
    }
}

Document.prototype.createElementFromHTML=function(string){
    var div=document.createElement('div');
    div.innerHTML=string.trim();
    return div.firstElementChild;
}

/*
    Integration map
*/

window.appMap={
    root:false,
    appdata:[],
    integrations:[],
    organization_id:false,
    connecting:undefined,
    mouseDownTime:0,
    border:20,
    snapToGrid:false,
    gridSnapSize:6,
    one_app_mode:false,
    undoStates:[],
    modes:[ //Each mode has a button selector and class which it adds to root container
        {name:'default',button:"#modeDefault",class:'modeDefault'},
        {name:'area',button:"#modeArea",class:'modeArea'},
        {name:'shape',button:"#modeShapes",class:'modeShape'}
    ],
    shapeSVGs:[
        {type:'parallelogram',svg:'<svg viewBox="0 0 16 10" width="16" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M2 0 L16 0 L14 10 L0 10z" /></svg>'},
        {type:'rectangle',svg:'<svg viewBox="0 0 16 10" width="16" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M0 0 L16 0 L16 10 L0 10z" /></svg>'},
        {type:'diamond',svg:'<svg viewBox="0 0 16 10" width="16" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M0 5 L8 10 L16 5 L8 0z" /></svg>'},
        {type:'circle',svg:'<svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg"><ellipse ry="8" rx="8" cy="8" cx="8" /></svg>'},
        {type:'rectanglewalls',svg:'<svg viewBox="0 0 16 10" width="16" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M0 0 L16 0 L16 10 L0 10z" /><path d="M2 0 L2 10" /><path d="M14 0 L14 10" /></svg>'},
        {type:'pill',svg:'<svg viewBox="0 0 16 10" width="16" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M0 5 C0 2.5,2.5 0,5 0 L11 0 C13.5 0,16 2.5,16 5 S13.5 10,11 10 L5 10 C2.5 10,0 7.5,0 5" /></svg>'},
        {type:'diamondpill',svg:'<svg viewBox="0 0 16 8" width="16" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M0 4L3 0L13 0L16 4L13 8L3 8z" /></svg>'},
        {type:'manual',svg:'<svg viewBox="0 0 16 10" width="16" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M0 3L16 0 v10H0z" /></svg>'},
        {type:'manualop',svg:'<svg viewBox="0 0 16 10" width="16" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M0 0 L16 0 L14 10 L2 10z" /></svg>'},
        {type:'delay',svg:'<svg viewBox="0 0 16 8" width="16" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h12C14 0 16 2 16 4C16 6 14 8 12 8H0z" /></svg>'},
        {type:'display',svg:'<svg viewBox="0 0 16 8" width="16" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M0 4L3 0H12C14 0 16 2 16 4C16 6 14 8 12 8H3z" /></svg>'},
        {type:'document',svg:'<svg viewBox="0 0 16 8" width="16" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M0 0 h16v7C8 4.15 8 9.85 0 6.45z" /></svg>'},
        {type:'connectordown',svg:'<svg viewBox="0 0 12 12" width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M0 0v6l6 6 6 -6V0z" /></svg>'},
        {type:'connectorright',svg:'<svg viewBox="0 0 12 12" width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h6l6 6 -6 6H0z" /></svg>'},
        {type:'connectorleft',svg:'<svg viewBox="0 0 12 12" width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M12 0h-6l-6 6 6 6H12z" /></svg>'},
    ],
    //Create application data, extend it with integration data and initialize everything else
    create:function(settings){
        window.appMap.organization_id=settings.organization_id;
        window.appMap.organization_uniqid=settings.organization_uniqid;
        window.appMap.root=settings.root; //jQuery object of a div containing the blueprint objects. Trying to transition from this
        window.appMap.rootDOM=settings.root[0]; //Reference to a DOM element containing the blueprint objects. Trying to using it from now on instead of jQuery
        window.appMap.toolbar=settings.toolbar; //jQuery object for the div that should contain the toolbar
        window.appMap.toolbarDOM=settings.toolbar?settings.toolbar[0]:undefined; //DOM element for the toolbar
        window.appMap.integrations=settings.integrations; //List of integrations from the DB
        window.appMap.readonly=(settings.readonly===true?true:false); //If this map is editable or readonly
        window.appMap.one_app_mode=(settings.one_app_mode!==undefined?settings.one_app_mode:false); //A mode when we only show one app and connected apps
        window.appMap.map_style=settings.map_style; //An object containing styles for apps, integrations, apps and the map itself
        window.appMap.min_width=settings.min_width; //Minimum and maximum sizes of the map to limit user's ability to resize
        window.appMap.min_height=settings.min_height;
        window.appMap.max_width=settings.max_width;
        window.appMap.max_height=settings.max_height;
        //Fill some defaults in the map style object
        if(typeof window.appMap.map_style.map!=='object') window.appMap.map_style.map={};
        if(window.appMap.map_style.map.gridSize==undefined) window.appMap.map_style.map.gridSize=20;
        if(parseInt(window.appMap.map_style.map.gridSize)<5) window.appMap.map_style.map.gridSize=5;
        if(parseInt(window.appMap.map_style.map.gridSize)>200) window.appMap.map_style.map.gridSize=200;
        //This function handles replacing old class names with the new ones
        //Refer to it when deciding to rename or remove some class that's applied to apps/connections/areas/etc
        window.appMap.map_style=window.appMap.legacyStyleReplace(window.appMap.map_style);
        //Get all apps into an appdata array
        window.appMap.root.find(".applicationNode").each(function(){
            var element=$(this);
            window.appMap.appdata.push({
                id:parseInt(element.attr('data-appid')),
                object:element,
                element:element[0],
                connections:0,
                connectedIDs:[]
            });
            window.appMap.applyStyleClasses('app',window.appMap.appdata[window.appMap.appdata.length-1].id);
        });
        window.appMap.updateIntegrations(settings.integrations);
        window.appMap.placeApps();
        //Create areas only if not in a single app mode
        if(window.appMap.one_app_mode===false){
            window.appMap.createAreas();
            window.appMap.createShapes();
        }
        window.appMap.redrawLines(); //Needs to go after shapes since it can update attached shape's position
        //Readonly blueprint
        if(window.appMap.readonly){
            window.appMap.root.addClass('appContainerReadonly');
        //Editable blueprint. Listen for mouse events and create the toolbar
        }else{
            window.appMap.root.addClass('appContainerEditable');
            //Deal with undo
            window.appMap.addUndoState();
            //Listen to keyboard events
            document.addEventListener('keydown',e=>{if(e.ctrlKey && e.keyCode==90) window.appMap.undo();}); //Ctrl+z
            //Listen to mouse and touch events, they are used for selecting, dragging, resizing and more.
            //These listeners must be added to window so we track if mouse if released ouside the window.
            window.addEventListener("mousedown",window.appMap.mouseDown,{passive:true});
            window.addEventListener("mousemove",window.appMap.mouseMove,{passive:true});
            window.addEventListener("mouseup",window.appMap.mouseUp,{passive:true});
            window.addEventListener("touchstart",window.appMap.mouseDown,{passive:true});
            window.addEventListener("touchmove",window.appMap.mouseMove,{passive:false});
            window.addEventListener("touchend",window.appMap.mouseUp,{passive:true});
            //Pass scroll event to mousemove, it handles it to make movement of the objects smoother
            document.querySelector(".appContainerScrollable").addEventListener('scroll',window.appMap.mouseMove,{passive:true})
            //Enter and leave events, at the moment only used when mouse enters and leaves the app tos how the menu
            document.body.addEventListener("mouseenter",window.appMap.mouseEnter,{capture:true,passive:true});
            document.body.addEventListener("mouseleave",window.appMap.mouseLeave,{capture:true,passive:true});
            //Adding listeners for all the registered events
            if(Array.isArray(window.appMap.eventsToRegister)){
                window.appMap.eventsToRegister.forEach(event=>document.addEventListener(event.name,event.function));
            }
            //Make canvas area resizeable
            window.appMap.makeMapResizeable();
            //Add a hidden app via modal
            $(".hidden_apps_btn").click(window.appMap.appsListHidden);
            //Activate always visible tools
            window.appMap.activatePersistantTools();
            //Set map to default mode
            window.appMap.setMode(window.appMap.modes[0]);
            //Create toolbar
            window.appMap.createMainToolbar();
        }
        //In single app mode we hide all apps that are not connected to a selected app
        if(window.appMap.one_app_mode!==false){
            window.appMap.appIsolate(settings.one_app_mode);
            //Move all visible apps to center
            window.appMap.mapBBox=window.appMap.rootDOM.getBoundingClientRect();
            //First find rectangle holding all visible apps, related to the root container
            var visRect={sx:9999,sy:9999,ex:0,ey:0};
            window.appMap.appdata.forEach(function(app){
                if(app.object.is(":visible")){
                    var rect=app.object[0].getBoundingClientRect();
                    visRect.sx=Math.min(visRect.sx,rect.x-window.appMap.mapBBox.x);
                    visRect.sy=Math.min(visRect.sy,rect.y-window.appMap.mapBBox.y);
                    visRect.ex=Math.max(visRect.ex,rect.x-window.appMap.mapBBox.x+rect.width);
                    visRect.ey=Math.max(visRect.ey,rect.y-window.appMap.mapBBox.y+rect.height);
                }
            });
            //Find how far is the center of the rectangle form the center of the map
            var move={
                x:(window.appMap.mapBBox.width/2)-visRect.sx-((visRect.ex-visRect.sx)/2),
                y:(window.appMap.mapBBox.height/2)-visRect.sy-((visRect.ey-visRect.sy)/2)
            };
            //Move all apps using that distance
            window.appMap.appdata.forEach(function(app){
                var pos=window.appMap.getPosition(app.object);
                window.appMap.setPosition(app.object,pos.x+move.x,pos.y+move.y);
            });
        }
        //Reposition arrows as page loads. After all the images are loaded it sometimes changes the size of the apps
        document.onreadystatechange=function(){
            if(document.readyState=="interactive" || document.readyState=="complete"){
                window.dispatchEvent(new Event('svglinesUpdate'));
            }
        }
        window.dispatchEvent(new Event('svglinesUpdate'));
    },
    //Saves all events we want to register on initialization if the map is interactive (not readonly)
    registerEvents:function(events){
        if(window.appMap.eventsToRegister==undefined) window.appMap.eventsToRegister=[];
        Object.keys(events).forEach(name=>window.appMap.eventsToRegister.push({name:name,function:events[name]}));
    },
    //Handle mouse/touch events by fitring custom events. The method above registers the functions that will listen to these events
    mouseDown:function(e){
        let doubleClick=false;
        //Save the position of mouse pointer where dragging started
        window.appMap.cursorPos=window.appMap.cursorRelative(e);
        window.appMap.mapRect=window.appMap.rootDOM.getBoundingClientRect();
        window.appMap.originalTarget=e.target;
        window.appMap.originalTargetRect=e.target.getBoundingClientRect();
        if(e.target.hasClass('applicationNode')){
            window.appMap.originalTargetType='app';
        }else if(e.target.hasClass('mapArea')){
            window.appMap.originalTargetType='area';
        }else if(e.target.hasClass('mapShape')){
            window.appMap.originalTargetType='shape';
            let shapeid=e.target.getAttribute('data-shapeid');
            window.appMap.originalTargetStyle=window.appMap.map_style.shapes.find(function(item){return item.id==shapeid;});
        }
        window.appMap.scrollable=document.querySelector(".appContainerScrollable");
        window.appMap.scroll={x:window.appMap.scrollable.scrollLeft,y:window.appMap.scrollable.scrollTop};
        //Check if it's double click
        if(Date.now()-window.appMap.mouseDownTime<500 && window.appMap.cursorOrigin!=undefined){
            let distance=Math.sqrt(Math.pow(window.appMap.cursorOrigin.x-window.appMap.cursorPos.x,2)+Math.pow(window.appMap.cursorOrigin.y-window.appMap.cursorPos.y,2));
            if(distance<2 && window.appMap.mouseDownTarget==e.target){
                doubleClick=true;
            }
        }
        //These vars are not temporary, they're also used to detect double clicks
        window.appMap.mouseDownTime=Date.now();
        window.appMap.cursorOrigin=window.appMap.cursorRelative(e);
        window.appMap.mouseDownTarget=e.target;
        //Create and fire the event
        let event=new CustomEvent(doubleClick?'appMapDoubleClick':'appMapMouseDown',{bubbles:true,detail:{
            cursor:window.appMap.cursorPos, //Cursor position relative to the map
            cursorOrigin:window.appMap.cursorOrigin, //Cursor origin relative to the map
            originalEvent:e, //Passing original event
            mode:window.appMap.mode.name //Selected mode
        }});
        event.shiftKey=e.shiftKey;
        event.ctrlKey=e.ctrlKey;
        event.altKey=e.altKey;
        e.target.dispatchEvent(event);
    },
    mouseMove:function(e){
        //Update map rect to make dragging properly work if map is being scrolled when dragging
        window.appMap.mapRect=window.appMap.rootDOM.getBoundingClientRect(); //We need to update it in case the map canvas was scrolled
        //If it's scroll event, treat it like a mouse move event but increment saved cursor position by scrolling difference
        if(e.type=='scroll' && window.appMap.scrollable!=undefined){
            let newScroll={x:window.appMap.scrollable.scrollLeft,y:window.appMap.scrollable.scrollTop};
            window.appMap.cursorPos.x+=newScroll.x-window.appMap.scroll.x;
            window.appMap.cursorPos.y+=newScroll.y-window.appMap.scroll.y;
            //Displace original target rect to a scrolled position, for resizing to be accurate while scrolling
            window.appMap.originalTargetRect.x-=newScroll.x-window.appMap.scroll.x;
            window.appMap.originalTargetRect.y-=newScroll.y-window.appMap.scroll.y;
            //Set the new scroll value
            window.appMap.scroll=newScroll;
        //Get cursor only if it's non-scroll event
        }else{
            window.appMap.cursorPos=window.appMap.cursorRelative(e);
        }
        //Difference between current cursor location and its origin (when the drag started)
        let translation=null;
        if(window.appMap.cursorOrigin!=undefined){
            translation={
                x:(window.appMap.cursorPos.x-window.appMap.cursorOrigin.x),
                y:(window.appMap.cursorPos.y-window.appMap.cursorOrigin.y)
            }
        }
        //Create and fire a custom event
        let event=new CustomEvent('appMapMouseMove',{bubbles:true,detail:{
            cursor:window.appMap.cursorPos, //Cursor position relative to the map
            cursorOrigin:window.appMap.cursorOrigin, //Cursor origin relative to the map
            originalTarget:window.appMap.originalTarget, //Element over which mouseDown happened
            originalTargetRect:window.appMap.originalTargetRect, //Original rect of the original target before dragging and resizing
            originalTargetType:window.appMap.originalTargetType, //Type of the original target: app, area, shape
            originalTargetStyle:window.appMap.originalTargetStyle, //Reference to a style for this item in map_style object
            translation:translation,
            originalEvent:e, //Passing original event
            mode:window.appMap.mode.name //Selected mode
        }});
        event.shiftKey=e.shiftKey;
        event.ctrlKey=e.ctrlKey;
        event.altKey=e.altKey;
        e.target.dispatchEvent(event);
    },
    mouseUp:function(e){
        window.appMap.cursorPos=window.appMap.cursorRelative(e);
        let dragDistance=Math.sqrt(Math.pow(window.appMap.cursorOrigin.x-window.appMap.cursorPos.x,2)+Math.pow(window.appMap.cursorOrigin.y-window.appMap.cursorPos.y,2));
        let event=new CustomEvent('appMapMouseUp',{bubbles:true,detail:{
            cursor:window.appMap.cursorPos, //Cursor position relative to the map
            cursorOrigin:window.appMap.cursorOrigin, //Cursor origin relative to the map
            originalTarget:window.appMap.originalTarget, //Element over which mouseDown happened
            originalTargetRect:window.appMap.originalTargetRect, //Original rect of the original target before dragging and resizing
            originalTargetType:window.appMap.originalTargetType, //Type of the original target: app, area, shape
            originalTargetStyle:window.appMap.originalTargetStyle, //Reference to a style for this item in map_style object
            dragDistance:dragDistance, //The distance between dtag start position and drag end position
            originalEvent:e, //Passing original event
            mode:window.appMap.mode.name //Selected mode
        }});
        event.shiftKey=e.shiftKey;
        event.ctrlKey=e.ctrlKey;
        event.altKey=e.altKey;
        e.target.dispatchEvent(event);
        //Unset temporary vars
        window.appMap.cursorPos=undefined;
        window.appMap.mapRect=undefined;
        window.appMap.originalTarget=undefined;
        window.appMap.originalTargetRect=undefined;
        window.appMap.originalTargetType=undefined;
        window.appMap.originalTargetStyle=undefined;
        window.appMap.scrollable=undefined;
        window.appMap.scroll=undefined;
    },
    //Leave and enter events only used to show/hide "+" buttons for apps
    mouseEnter:function(e){
        if(e.target.hasClass('applicationNode') && e.target.parentNode.hasClass('appContainer')){
            if(!window.appMap.selecting && !window.appMap.dragging){
                const appdata=window.appMap.appdata;
                if(window.appMap.connecting!=undefined && !window.appMap.connecting.lock){
                    if(e.target.getAttribute('data-appid')!=window.appMap.connecting.fromID){
                        e.target.addClass('hover');
                        window.appMap.connecting.line.to=e.target;
                    }
                }else if(!e.target.querySelector('.applicationMenu')){
                    let menu=document.querySelector(".applicationMenu").cloneNode(true);
                    menu.removeClass('d-none');
                    e.target.append(menu);
                }
            }
        }
    },
    mouseLeave:function(e){
        if(e.target.hasClass('applicationNode')){
            if(window.appMap.connecting!=undefined && !window.appMap.connecting.lock){
                e.target.removeClass('hover');
                window.appMap.connecting.line.to=window.appMap.cursorFollow;
            }else if(!window.appMap.dragging){
                let menu=e.target.querySelector('.applicationMenu');
                if(menu) menu.remove();
            }
        }
    },
    setMode:function(mode){
        if(!window.appMap.rootDOM.hasClass(mode.class)){
            //Remove other modes' classes
            window.appMap.modes.forEach(m=>{if(m.class) window.appMap.rootDOM.removeClass(m.class);});
            //Add clicked mode
            window.appMap.rootDOM.addClass(mode.class);
            window.appMap.mode=mode;
            window.appMap.appUnIsolate();
            window.appMap.deselectEverything();
        }
    },
    //Select an app, connection or area
    selectElement:function(e){
        let element=e.target;
        //If target is not and app, an arrow or an area, it means it's a child of one and we need to find the suitable parent
        if(!element.hasClass('applicationNode') && !element.hasClass('mapArrrow') && !element.hasClass('mapArea') && !element.hasClass('mapShape')){
            const ancestor=element.closest(".applicationNode,.mapArrow,.mapArea,.mapShape");
            if(ancestor) element=ancestor;
            else if(element.hasClass('connectionDescription')){ //When clicking on integration description
                element=window.appMap.rootDOM.querySelector('.mapArrow[data-intid="'+e.target.getAttribute('data-intid')+'"]');
            }else return false;
        } 
        if(!element.hasClass('selected')){
            if(!e.ctrlKey && !e.shiftKey) window.appMap.deselectEverything(); //Allow selecting multiple things when ctrl is pressed
            element.addClass('selected');
            window.appMap.createMainToolbar();
        }else if(e.ctrlKey || e.shiftKey){
            element.removeClass('selected');
            window.appMap.createMainToolbar();
        }
    },
    //Deselects every app, integration and area
    deselectEverything:function(){
        window.appMap.rootDOM.querySelectorAll('.applicationNode.selected,.mapArrow.selected,.mapArea.selected,.mapShape.selected').forEach(element=>element.removeClass('selected'));
        window.appMap.createMainToolbar();
    },
    makeMapResizeable:function(){
        //Create and update the resize tooltip
        function createAppMapTip(e){
            window.appMap.resizeTip=document.createElement("div");
            window.appMap.resizeTip.className="appMapResizeTip";
            document.body.appendChild(window.appMap.resizeTip);
            updateAppMapTip(e);
        }
        function updateAppMapTip(e){
            window.appMap.resizeTip.innerHTML=window.appMap.rootDOM.clientWidth+"×"+window.appMap.rootDOM.clientHeight;
            //Calculate position for the tip based on cursor
            var pos={
                left:e.pageX-window.appMap.resizeTip.clientWidth/2,
                top:e.pageY+16
            }
            //Keep tooltip within the window so we don't add scrollbars to the window
            var margin=5;
            var tipRect=window.appMap.resizeTip.getBoundingClientRect();
            //var tipRect=window.appMap.resizeTip[0].getBoundingClientRect();
            if(pos.left+tipRect.width+margin>window.innerWidth) pos.left=window.innerWidth-tipRect.width-margin;
            else if(pos.left+margin<0) pos.left=margin;
            if(pos.top+tipRect.height+margin>window.innerHeight) pos.top=window.innerHeight-tipRect.height-margin;
            else if(pos.top+margin<0) pos.top=margin;
            window.appMap.resizeTip.style.left=pos.left+"px";
            window.appMap.resizeTip.style.top=pos.top+"px";
        }
        function removeAppMapTip(){
            window.appMap.resizeTip.remove();
            delete window.appMap.resizeTip;
        }
        //Handling mouse
        function resizeMouseDown(e){
            window.appMap.resizeOrigin=window.appMap.cursor(e); //Remember mouse position
            window.appMap.resizeRect=window.appMap.rootDOM.getBoundingClientRect(); //Remember map size
            //Find minimum resize area by checking all bounding boxes inside the root element except some UI icons
            window.appMap.resizeMinSize={x:window.appMap.min_width,y:window.appMap.min_height};
            window.appMap.rootDOM.querySelectorAll(':scope > :not(.dragIcon):not(.appMapLoading)').forEach(item=>{
                var oBBox=item.getBoundingClientRect();
                window.appMap.resizeMinSize.x=Math.max(window.appMap.resizeMinSize.x,oBBox.x-window.appMap.resizeRect.x+oBBox.width);
                window.appMap.resizeMinSize.y=Math.max(window.appMap.resizeMinSize.y,oBBox.y-window.appMap.resizeRect.y+oBBox.height);
            });
            //Remember resize direction. x is doubled because the element is centered and we need to add 2x of width
            if(e.target.hasClass("resizeLeft")) window.appMap.resizeDirection={x:-2,y:0};
            else if(e.target.hasClass("resizeRight")) window.appMap.resizeDirection={x:2,y:0};
            else if(e.target.hasClass("resizeBottom")) window.appMap.resizeDirection={x:0,y:1};
            else if(e.target.hasClass("resizeBottomLeft")) window.appMap.resizeDirection={x:-2,y:1};
            else if(e.target.hasClass("resizeBottomRight")) window.appMap.resizeDirection={x:2,y:1};
            //Create events
            window.addEventListener('mousemove',resizeMouseMove);
            window.addEventListener('mouseup',resizeMouseUp);
            //Create tooltip
            createAppMapTip(e);
        }
        function resizeMouseMove(e){
            //Compare new cursor position to the original one and calculate the difference
            var cursor=window.appMap.cursor(e);
            var resizeDelta={
                x:(cursor.x-window.appMap.resizeOrigin.x)*window.appMap.resizeDirection.x,
                y:(cursor.y-window.appMap.resizeOrigin.y)*window.appMap.resizeDirection.y
            }
            //Add difference in cursor position to original size of the map and assign new size to map's css
            Object.assign(window.appMap.rootDOM.parentNode.style,{
                width:Math.min(Math.max(window.appMap.resizeRect.width+resizeDelta.x,window.appMap.resizeMinSize.x),window.appMap.max_width)+"px", //Keep width between minimum width and 3000
                height:Math.min(Math.max(window.appMap.resizeRect.height+resizeDelta.y,window.appMap.resizeMinSize.y),window.appMap.max_height)+"px" //Keep height between minimum height and 3000
            });
            //Update tooltip
            updateAppMapTip(e);
        }
        function resizeMouseUp(e){
            //Remove temporary variables
            delete window.appMap.resizeOrigin;
            delete window.appMap.resizeRect;
            delete window.appMap.resizeMinSize;
            delete window.appMap.resizeDirection;
            //Save map's new size to the map_style object
            if(window.appMap.map_style.map==undefined) window.appMap.map_style.map={};
            Object.assign(window.appMap.map_style.map,{
                width:window.appMap.rootDOM.clientWidth,
                height:window.appMap.rootDOM.clientHeight
            });
            window.appMap.saveMapStyle('mapSize');
            //Detach events mouse events
            window.removeEventListener('mousemove',resizeMouseMove);
            window.removeEventListener('mouseup',resizeMouseUp);
            //Remove tooltip
            removeAppMapTip();
        }
        //Bind onclick to all resize handles
        document.querySelectorAll('.appMapResize').forEach(item=>{item.addEventListener('mousedown',resizeMouseDown);})
    },

    //Update app data with data from integrations
    updateIntegrations:function(integrations){
        if(integrations==undefined){ //If integrations are not provided, we get them and call self again
            $.ajax({
                type:'GET',
                url:'/organization/'+window.appMap.organization_id+'/integrations/json',
                datatype:'json',
                beforeSend:function(){
                    window.appMap.root.find(".appMapLoading").remove();
                    window.appMap.root.append("<i class='appMapLoading fas fa-2x fa-spinner fa-pulse'></i>");
                },
                success:function(data,status,xhr){
                    window.appMap.root.find(".appMapLoading").remove();
                    $(".modal-backdrop").remove();
                    window.appMap.clearLines();
                    window.appMap.updateIntegrations(data);
                    window.appMap.redrawLines();
                },
                error:function(xhr,status,error){
                    window.appMap.root.find(".appMapLoading").remove();
                    alertModal('Error reloading integrations')
                }
            });
        }else{ //If integrations are provided, we use them
            window.appMap.integrations=integrations;
            var appdata=window.appMap.appdata?window.appMap.appdata:0;
            for(var i=0;i<appdata.length;i++){
                appdata[i].connections=0;
                appdata[i].connectedIDs=[];
                appdata[i].connectionIDs=[];
            }
            for(var i=0;i<integrations.length;i++){
                try{
                    appFrom=appdata.find(function(item){return item.id==integrations[i].appID;});
                    appFrom.connections++
                    appFrom.connectedIDs.push(parseInt(integrations[i].appIDtarget));
                    appFrom.connectionIDs.push(parseInt(integrations[i].id));
                    appTo=appdata.find(function(item){return item.id==integrations[i].appIDtarget;});
                    appTo.connections++
                    appTo.connectedIDs.push(parseInt(integrations[i].appID));
                    appTo.connectionIDs.push(parseInt(integrations[i].id));
                }catch(error){}
            }
            appdata.sort((a,b)=>(a.connections<b.connections)?1:(a.connections>b.connections?-1:0));
        }
    },
    addApp:function(appHtml,parentDiv,position){
        var appNode=$(appHtml);
        if(parentDiv==undefined){
            if(position!=undefined){
                left=Math.max(position.x,0);
                top=Math.max(position.y,0);
                left=Math.min(position.x,window.appMap.root.width());
                window.appMap.setPosition(appNode,left,top);
                window.appMap.root.append(appNode);
            }else{
                var left=window.appMap.border;
                var top=window.appMap.border;
                window.appMap.setPosition(appNode,left,top);
                window.appMap.root.append(appNode);
                //Choose a good spot for new app
                var moving='right';
                var pixels=10;
                iterations=0;
                while(window.appMap.overlapsWithAnySibbling(appNode[0])){
                    if(moving=='right') left+=pixels;
                    else if(moving=='down') top+=pixels;
                    else if(moving=='random'){
                        left=window.appMap.rnd(window.appMap.border,window.appMap.root.width()-window.appMap.border-appNode.outerWidth());
                        top=window.appMap.rnd(window.appMap.border,window.appMap.root.height()-window.appMap.border-appNode.outerHeight());
                    }
                    window.appMap.setPosition(appNode,left,top);
                    if(left+window.appMap.border+appNode.outerWidth()>window.appMap.root.width()){
                        left=window.appMap.border;
                        moving='down'
                    }
                    if(top+window.appMap.border+appNode.outerHeight()>window.appMap.root.height()){
                        top-=pixels;
                        moving='right'
                    }
                    iterations++;
                    if(iterations>400) moving='random';
                    if(iterations>600) break;
                }
            }
            window.appMap.appdata.push({
                id:parseInt(appNode.attr('data-appid')),
                object:appNode,
                connections:0
            });
        }else{
            parentDiv.append(appNode);
            //If adding an app to other div than a map, we make it draggable,
            //let user drag it onto the map
            window.appMap.makeHiddenAppDraggable(appNode);
        }
        appNode.addClass('newNode').removeClass('hide');
        setTimeout(function(){appNode.removeClass('newNode');},5000);
        return appNode;
    },

    //Reset how the apps are positioned and create new ones
    resetPositions:function(){
        confirmModal("The layout of applications in this map will be destroyed and their positions will be reset. Do you wish to proceed?",function(e){
            window.appMap.appUnIsolate();
            window.appMap.placeApps();
            window.appMap.redrawLines();
        });
    },

    //Place apps on the map
    placeApps:function(){
        console.time('placeApps()');
        let appdata=window.appMap.appdata; //Just to not put the whole name all the time
        let maxtop=0
        let mintop=maxtop;
        //Update transform for apps with positions, set other apps for position auto-update below
        appdata.forEach(app=>{
            if(!app.ignore){
                let posTop=parseFloat(app.element.style.top);
                let posLeft=parseFloat(app.element.style.left);
                if(posLeft==0 && posTop==0){
                    app.hasNoPosition=true;
                }else{
                    if(posTop<mintop) mintop=posTop;
                    let appRect=app.element.getBoundingClientRect();
                    if(posTop+appRect.height>maxtop) maxtop=posTop+appRect.height;
                    window.appMap.setPosition(app.element,posLeft,posTop);
                }
            }
        });

        //Calculate positions for unpositioned apps with connections starting with map center and moving outwards
        let rootRect=window.appMap.rootDOM.getBoundingClientRect();
        let center={x:rootRect.width/2,y:rootRect.height/2};
        appdata.forEach(app=>{
            if(!app.ignore && app.connections>0 && app.hasNoPosition){
                let appRect=app.element.getBoundingClientRect();
                let left=center.x-appRect.width/2;
                let top=center.y-appRect.height/2;
                window.appMap.setPosition(app.element,left,top);
                let distance=20;
                while(window.appMap.overlapsWithAnySibbling(app.element) || appRect.x<0 || appRect.x+appRect.width>rootRect.width){
                    distance+=50;
                    var rv=window.appMap.randomVector2(distance);
                    var point={x:center.x+rv.x,y:center.y+rv.y};
                    left=point.x-appRect.width/2;
                    top=point.y-appRect.height/2;
                    window.appMap.setPosition(app.element,left,top);
                    if(top<mintop) mintop=top;
                    if(top+appRect.height>maxtop) maxtop=top+appRect.height;
                }
            }
        });
        //Set container height to exact height of the graph
        window.appMap.rootDOM.style.height=maxtop-mintop;
        //If apps gone beyond top border, move them all down
        if(mintop<0){
            appdata.forEach(app=>{
                if(!app.ignore){
                    pos=window.appMap.getPosition(app.element);
                    window.appMap.setPosition(app.element,pos.left,pos.top+Math.abs(mintop));
                }
            });
        }

        //Position all applications that are not connected
        let margin=window.appMap.border;
        let left=margin;
        let top=margin;
        rootRect=window.appMap.rootDOM.getBoundingClientRect();
        appdata.forEach(app=>{
            if(!app.ignore && app.connections==0 && app.hasNoPosition){
                let appRect=app.element.getBoundingClientRect();
                if(left+appRect.width+margin>rootRect.width){
                    left=margin;
                    top+=appRect.height+margin;
                }
                window.appMap.setPosition(app.element,left,top);
                left+=appRect.width+margin;
                if(top+appRect.height>maxtop) maxtop=top+appRect.height;
            }
        });

        console.timeEnd('placeApps()');
    },

    //Removes the lines and unsets the line objects
    clearLines:function(force){
        console.time("clearLines()");
        let appdata=window.appMap.appdata;
        for(let i=0;i<appdata.length;i++){
            if(appdata[i].svglines!=undefined){
                for(var l=0;l<appdata[i].svglines.length;l++){
                    try{
                        if(!$(appdata[i].svglines[l].path).hasClass('fadeOut') || force===true){
                            //Remove description
                            if(appdata[i].svglines[l].integration.descriptionElement!=undefined){
                                appdata[i].svglines[l].integration.descriptionElement.remove();
                                appdata[i].svglines[l].integration.descriptionElement=undefined;
                            }
                            //Remove FAQs
                            if(appdata[i].svglines[l].integration.faqElements!=undefined){
                                appdata[i].svglines[l].integration.faqElements.forEach(faqElement=>{faqElement.remove();});
                                appdata[i].svglines[l].integration.faqElements=undefined;
                            }
                            //Remove line
                            appdata[i].svglines[l].remove();
                        }
                    }catch(error){}
                }
                appdata[i].svglines=appdata[i].svglines.filter(function(l){return !l.removed;});
                if(appdata[i].svglines.length==0) appdata[i].svglines=undefined;
            }
        }
        console.timeEnd("clearLines()");
    },
    //Clear and create the lines from scratch
    redrawLines:function(){
        window.appMap.clearLines(); //Clear existing lines first
        console.time("redrawLines()");
        var appdata=window.appMap.appdata;
        var integrations=window.appMap.integrations;
        for(let i=0;i<integrations.length;i++){
            var integration=integrations[i];
            //In one-app mode we only draw the arrows for the visible app
            if(window.appMap.one_app_mode!=false && window.appMap.one_app_mode!=integration.appID && window.appMap.one_app_mode!=integration.appIDtarget){
                continue;
            }
            var fromKey=appdata.findIndex(function(item){return item.id==integration.appID});
            var toKey=appdata.findIndex(function(item){return item.id==integration.appIDtarget});
            if(fromKey>-1 && toKey>-1){
                var domFrom=appdata[fromKey].object[0];
                var domTo=appdata[toKey].object[0];
                var exists=false; //If line exists we don't redraw it
                //Get lines connected to either From or To app in one array
                var lines=(appdata[fromKey].svglines!=undefined?appdata[fromKey].svglines:[]).concat(appdata[toKey].svglines!=undefined?appdata[toKey].svglines:[]);
                for(let l of lines){
                    if((l.from==domFrom && l.to==domTo) || (l.to==domFrom && l.from==domTo)){
                        exists=true;
                        break;
                    }
                }
                if(!exists){
                    var style=window.appMap.style('connection',integration.id);
                    var line=new svgline(domFrom,domTo,{
                        startArrow:((integration.directionID==1 || integration.directionID==2)?true:false),
                        endArrow:((integration.directionID==0 || integration.directionID==2)?true:false),
                        parent:window.appMap.root[0],
                        interactive:(window.appMap.readonly?0:1)
                    });
                    //So we could reference the integration the line is based on
                    line.integration=integration;
                    //Get shapes which are attached to this line
                    integration.lineAttachments=[];
                    if(window.appMap.map_style.shapes!=undefined){
                        window.appMap.map_style.shapes.forEach(shape=>{
                            if(shape.integrationID==integration.id){
                                integration.lineAttachments.push(document.querySelector(".mapShape[data-shapeid='"+shape.id+"']"));
                            }
                        });
                    }
                    //Add description if needed, update its position
                    line.onUpdate=window.appMap.onLineUpdate;
                    //Mouse over and out
                    line.mouseover=function(e,svgline){window.appMap.hoverLine=svgline;};
                    line.mouseout=function(e,svgline){window.appMap.hoverLine=undefined;};
                    //Add integration id to quickly find related integration
                    $(line.svg).attr('data-intid',integration.id);
                    //Add line to this app's data
                    if(appdata[fromKey].svglines==undefined) appdata[fromKey].svglines=[];
                    appdata[fromKey].svglines.push(line);
                    if(appdata[toKey].svglines==undefined) appdata[toKey].svglines=[];
                    appdata[toKey].svglines.push(line);
                    //Add line to integrations data
                    integration.svgline=line;
                    //Apply connection styles
                    var style=window.appMap.applyStyleClasses('connection',integration.id);
                }
            }
        }
        svgline.prototype.updateLinesSpread();
        console.timeEnd("redrawLines()");
    },
    //When a line is updated it calls this function. If you're dragging a connected app(s) this function 
    //is called few dozen times per second for each arrow that changed their positions.
    onLineUpdate:function(svgline){
        var integration=svgline.integration;
        //We have a description and the setting is to show it
        if(integration.description_show!=0 && integration.description!=undefined && integration.description.length>0){
            //We don't have a discription element
            if(integration.descriptionElement==undefined){
                //Create description element
                integration.descriptionElement=document.createElementFromHTML(`<div class='connectionDescription'></div>`);
                integration.descriptionElement.setAttribute('data-intid',integration.id);
                integration.descriptionElement.setAttribute('data-lineOrder',0);
                integration.descriptionElement.innerHTML=integration.description.replace(/\n/g,'<br/>');
                //Append it to dom
                window.appMap.rootDOM.append(integration.descriptionElement);
                //Attach to line
                integration.lineAttachments.unshift(integration.descriptionElement);
                //Aplly the style once more to update the style of the description
                window.appMap.applyStyleClasses('connection',integration.id);
            }
        //We shouldn't show the description but we do have the element
        }else if(!integration.description_show && integration.descriptionElement!=undefined){
            integration.descriptionElement.remove();
            integration.descriptionElement=undefined;
        }
        //Create faq elements if needed
        if(integration.faqs!=undefined && Array.isArray(integration.faqs) && integration.faqs.length>0){
            if(integration.faqElements==undefined){
                integration.faqElements=[];
                integration.faqs.forEach(faq=>{
                    let faqElement=document.createElementFromHTML(`<div class='connectionFaq'></div>`);
                    faqElement.setAttribute('data-intid',integration.id);
                    faqElement.setAttribute('data-faqid',faq.id);
                    //faqElement.setAttribute('title',faq.title);
                    //$(faqElement).powerTip({placement:'n',fadeInTime:50,fadeOutTime:50,closeDelay:50});
                    //Add it to integration
                    integration.faqElements.push(faqElement);
                    //Append it to dom
                    window.appMap.rootDOM.append(faqElement);
                });
                //Aplly the style once more to update the style of the description
                window.appMap.applyStyleClasses('connection',integration.id);
            }
        }
        //Sort line attachments using lineOrder attribute. Keep in mind that description will always be 0
        integration.lineAttachments.sort((a,b)=>{
            let aO=parseInt(a.getAttribute('data-lineOrder'));
            let bO=parseInt(b.getAttribute('data-lineOrder'));
            if(aO<bO) return -1;
            else if(aO>bO) return 1;
            else return 0;
        });
        //Position each line attachent on the line
        integration.lineAttachments.forEach((attachment,i)=>{
            let pos=(1/(integration.lineAttachments.length+1))*(i+1);
            window.appMap.positionOnLine(attachment,integration.svgline,pos);
        });
        //Position FAQs in the center of the line
        if(integration.faqElements!=undefined){
            let margin=-18;
            let point=integration.svgline.getPoint(0.5);
            let faqRect=integration.faqElements[0].getBoundingClientRect(); //They all should have equal size, otherwise this wouldn't work
            let count=integration.faqElements.length;
            let allFaqsWidth=count*faqRect.width+(count-1)*margin;
            //Check if point's Y intersects with shapes or descriptions for this line and move it higher if needed
            window.appMap.mapBBox=window.appMap.rootDOM.getBoundingClientRect();
            integration.lineAttachments.forEach((attachment,i)=>{
                const rect=attachment.getBoundingClientRect();
                const rectLocal={
                    left:rect.left-window.appMap.mapBBox.left,
                    right:rect.right-window.appMap.mapBBox.left,
                    top:rect.top-window.appMap.mapBBox.top,
                    bottom:rect.bottom-window.appMap.mapBBox.top,
                }
                if(point.x>rectLocal.left && point.x<rectLocal.right && point.y+faqRect.height/2>rectLocal.top){
                    point.y=rect.top-window.appMap.mapBBox.y;
                }
            });
            //Position FAQ elements
            integration.faqElements.slice().reverse().forEach((faqElement,i)=>{
                window.appMap.setPosition(
                    faqElement,
                    point.x-allFaqsWidth/2+i*(faqRect.width+margin),
                    point.y-faqRect.height*1.2
                );
            });
        }
    },
    //Position something along the integration arrow
    positionOnLine:function(element,line,position){
        let descRect=element.getBoundingClientRect(); //Get element box
        let point=line.getPoint(position); //Get position on the line
        let descPos={
            x:point.x-descRect.width*0.5,
            y:point.y-descRect.height*0.5,
        }
        if(window.appMap.mapBBox==undefined) window.appMap.mapBBox=window.appMap.rootDOM.getBoundingClientRect();
        //Keep description within map, adding 20px padding to addjust for the box-shadow it has
        descPos.x=Math.max(descPos.x,20);
        descPos.x=Math.min(descPos.x,window.appMap.mapBBox.width-descRect.width-20);
        descPos.y=Math.max(descPos.y,20);
        descPos.y=Math.min(descPos.y,window.appMap.mapBBox.height-descRect.height-20);
        //Set desscription's position
        window.appMap.setPosition(element,descPos.x,descPos.y);
    },

    //Create areas
    createAreas:function(){
        if(window.appMap.map_style.areas!=undefined){
            window.appMap.map_style.areas.forEach(function(style,index){
                var element=window.appMap.createAreaElement(style.title,style.description);
                if(style.id==undefined) style.id=new Date().valueOf(); //Actually never happens
                element.setAttribute('data-areaid',style.id);
                window.appMap.rootDOM.append(element);
                window.appMap.setPosition(element,style.x,style.y);
                element.style.width=style.width+"px";
                element.style.height=style.height+"px";
                window.appMap.applyStyleClasses('area',style.id);
            });
        }
    },
    //Create area element
    createAreaElement(title,description){
        let area=document.createElementFromHTML(`
            <div class='mapArea'>
                <div>
                    <div>
                        <div class="title"></div>
                        <div class="description"></div>
                    </div>
                </div>
            </div>
        `);
        area.querySelector(".title").innerHTML=(title?'<span>'+title+'</span>':' ');
        area.querySelector(".description").innerHTML=(description?'<span>'+description+'</span>':' ');
        return area;
    },

    //Create shapes
    createShapes:function(){
        if(window.appMap.map_style.shapes!=undefined){
            window.appMap.map_style.shapes.forEach(function(style,index){
                let shape=window.appMap.createShapeElement(style.type,style.title,style.description);
                shape.setAttribute('data-shapeid',style.id);
                if(style.integrationID && style.lineOrder!=undefined){
                    shape.setAttribute('data-lineOrder',style.lineOrder);
                }
                window.appMap.rootDOM.append(shape);
                window.appMap.setPosition(shape,style.x,style.y);
                shape.style.width=style.width+"px";
                shape.style.height=style.height+"px";
                window.appMap.applyStyleClasses('shape',style.id);
            });
        }
    },
    //Create single shape element
    createShapeElement:function(type,title,description){
        let shape=document.createElementFromHTML(`
            <div class='mapShape'>
                <div>
                    <div class="title"></div>
                    <div class="description"></div>
                </div>
            </div>
        `);
        shape.querySelector(".title").innerHTML=(title?'<span>'+title+(description?' <a class="shapePopover text-reset">ⓘ</a>':'')+'</span>':'');
        //shape.querySelector(".description").innerHTML=(description?'<span>'+description+'</span>':'');


        //Append SVG image
        shape.prepend(window.appMap.getShapeSVG(type));
        return shape;
    },
    //Create SVG element for a shape
    getShapeSVG:function(type){
        let svg=document.createElementFromHTML(window.appMap.shapeSVGs.find(shapeSVG=>shapeSVG.type==type).svg);
        svg.setAttribute('preserveAspectRatio',"none");
        return svg;
    },

    updateGrid:function(value){
        if(value!=undefined){
            var newGridSize=Math.min(200,Math.max(parseInt(value),5));
            if(window.appMap.map_style.map.gridSize!=newGridSize){
                window.appMap.map_style.map.gridSize=newGridSize;
                window.appMap.saveMapStyle('grid');
            }
        }
        
        $('#gridSizeField,#gridSizeSlider').val(window.appMap.map_style.map.gridSize);

        if(window.appMap.rootDOM.hasClass('showGrid')){
            
            $("#gridToggle").addClass('active');

            window.appMap.snapToGrid=true;
            var canvas=document.createElement("canvas");
            canvas.width=window.appMap.map_style.map.gridSize;
            canvas.height=window.appMap.map_style.map.gridSize;
            var context=canvas.getContext('2d');
            context.fillStyle="rgba(0,0,0,0.1)";
            context.fillRect(window.appMap.map_style.map.gridSize-1,0,1,window.appMap.map_style.map.gridSize);
            context.fillRect(0,window.appMap.map_style.map.gridSize-1,window.appMap.map_style.map.gridSize,1);
            var dataURL=canvas.toDataURL("image/png");
            window.appMap.rootDOM.style.backgroundImage="url('"+dataURL+"')";
            window.appMap.rootDOM.style.backgroundPosition="top left";
            window.appMap.rootDOM.style.backgroundRepeat="repeat";
        }else{
            window.appMap.snapToGrid=false;
            
            $("#gridToggle").removeClass('active');

            window.appMap.rootDOM.style.backgroundImage="none";
        }
    },
    mapModeToggle:function(buttonSelector,toggleClass,callback){
        var button=document.querySelector(buttonSelector);
        if(button!=null){
            button.addEventListener("click",function(){
                window.appMap.rootDOM.toggleClass(toggleClass);
                this.toggleClass('active');
                if(callback!=undefined) callback();
            });
            if(window.appMap.rootDOM.hasClass(toggleClass)){
                button.addClass('active');
            }
        }
    },
    activatePersistantTools:function(){
        //Add application to map button
        var addApplicationToMapButton=document.querySelector("#addApplicationToMap");
        if(addApplicationToMapButton!=null){
            addApplicationToMapButton.addEventListener("click",function(){
                window.appMap.rootDOM.removeClass('areaMode');
                window.appMap.appUnIsolate();
                window.appMap.addApplicationToMap();
            });
        }
        //Grid toggler
        window.appMap.mapModeToggle("#gridToggle",'showGrid',function(){
            window.appMap.updateGrid();
        });
        //Grid size controls
        document.val("#gridSizeSlider,#gridSizeField",window.appMap.map_style.map.gridSize);
        document.bind("#gridSizeSlider,#gridSizeField",'click',function(e){e.stopImmediatePropagation();});
        document.bind("#gridSizeSlider",'propertychange change click keyup input paste',function(e){window.appMap.updateGrid(e.target.value);});
        document.bind("#gridSizeField",'propertychange change click paste',function(e){window.appMap.updateGrid(e.target.value);});
        //Costs toggler
        window.appMap.mapModeToggle("#costsToggle",'showCosts');
        //enable PowerTip
        //$('#addApplicationToMap,#gridSettings,#gridToggle,#costsToggle').powerTip({placement:'n',fadeInTime:50,fadeOutTime:50,closeDelay:50});
    },
    //Instantiate a toolbar and make it work
    createMainToolbar:function(){
        if(window.appMap.readonly==true) return false;
        window.appMap.toolbarDOM.innerHTML="";
        var alignOptions=document.querySelector("body > .alignOptions");
        if(alignOptions!=null) alignOptions.remove();
        //Insert mode select bar and make it work
        window.appMap.toolbar.html($('#modeSelect').clone().removeClass('d-none'));
        //Bind mode switching to toolbar buttons
        window.appMap.modes.forEach(mode=>{
            var button=window.appMap.toolbarDOM.querySelector(mode.button);
            if(button!=null){
                button.addEventListener("click",()=>{
                    if(!window.appMap.rootDOM.hasClass(mode.class)){
                        window.appMap.setMode(mode);
                    }else{
                        window.appMap.setMode(window.appMap.modes[0]); //Set default mode if clicking on the mode that's already on
                    }
                });
                //If root has this class, we highlight this button
                if(window.appMap.rootDOM.hasClass(mode.class)) button.addClass('active');
            }
        });

        //var selected=window.appMap.rootDOM.querySelector(":scope > .selected");
        var selected=window.appMap.root.children(".selected");
        //Check if selected objects are of the same type
        var sameType=true;
        if(selected.length>1){
            var testType=false;
            selected.each(function(){
                if($(this).hasClass('applicationNode')){
                    if(testType==false) testType='app';
                    else if(testType!='app'){
                        sameType=false;
                        return false;
                    }
                }else if($(this).hasClass('mapArrow')){
                    if(testType==false) testType='connection';
                    else if(testType!='connection'){
                        sameType=false;
                        return false;
                    }
                }else if($(this).hasClass('mapArea')){
                    if(testType==false) testType='area';
                    else if(testType!='area'){
                        sameType=false;
                        return false;
                    }
                }
            });
        }

        var selcount={apps:0,connections:0,areas:0};
        selected.each(function(){
            if($(this).hasClass('applicationNode')) selcount.apps++;
            else if($(this).hasClass('mapArrow')) selcount.connections++;
            else if($(this).hasClass('mapArea')) selcount.areas++;
            else if($(this).hasClass('mapShape')) selcount.shapes++;
        });

        //If more than one app is selected we show align options
        if(selcount.apps>1 && !window.appMap.selecting){
            window.appMap.makeAlignToolbar(selcount);
        }

        //Append the tools
        if(selected.length>0 && sameType){
            var ids=[];
            if(selected.hasClass('applicationNode')){
                var type='app';
                var prefix='ns';
                selected.each(function(){ids.push($(this).attr('data-appid'));});
            }else if(selected.hasClass('mapArrow')){
                var type='connection';
                var prefix='cs';
                selected.each(function(){ids.push($(this).attr('data-intid'));});
            }else if(selected.hasClass('mapArea')){
                var type='area';
                var prefix='as';
                selected.each(function(){ids.push($(this).attr('data-areaid'));});
            }else if(selected.hasClass('mapShape')){
                var type='shape';
                var prefix='ss';
                selected.each(function(){ids.push($(this).attr('data-shapeid'));});
            }
            var ctoolbar=$('.'+type+'Tools').clone().removeClass('d-none');
            window.appMap.toolbar.append(ctoolbar);
            var style=window.appMap.style(type,ids);
            //Fill the shape-type menu with our predefined shapeSVGs
            ctoolbar.find('.shapetype-menu').each(function(){
                let menu=$(this);
                let hiddenItem=menu.find('.dropdown-item');
                window.appMap.shapeSVGs.forEach(shapeSVG=>{
                    let item=hiddenItem.clone().removeClass('d-none');
                    item.attr('data-value','ss-type-'+shapeSVG.type);
                    item.html(shapeSVG.svg);
                    item.find('svg')[0].addClass('position-demo'); //So it wouldn't fill svg with black
                    menu.append(item);
                });
            });
            //Set selected values for dropdown buttons
            ctoolbar.find('.styletext').each(function(){
                try{
                    var property=this.className.match(new RegExp("\\bstyletext-[a-zA-Z0-9]+","g"))[0].split('-')[1];
                    if(style[property]!=undefined && style[property]!='%?%'){
                        var el=ctoolbar.find(".set-item-style[data-value='"+prefix+"-"+property+"-"+style[property]+"']");
                    }else{
                        var el=ctoolbar.find(".set-item-style.default[data-value^="+prefix+"-"+property+"-]");
                        if(el.length==0) el=ctoolbar.find(".set-item-style[data-value^="+prefix+"-"+property+"-]").first();
                    }
                    this.innerHTML=el.html().trim();
                    if(style[property]=='%?%'){
                        $(this).children().first().addClass('conflictingValues');
                    }
                }catch(error){}
            });
            //Set active state for toggle buttons
            ctoolbar.find('.toggle-item-style').each(function(){
                var btn=this;
                var values=this.getAttribute('data-values').split(',');
                values.forEach(function(value,key){
                    var v=value.split('-');
                    if(style[v[1]]!=undefined && style[v[1]]==v[2] && key>0){
                        $(btn).addClass('active');
                    }else if(style[v[1]]=='%?%'){
                        $(btn).children(0).addClass('conflictingValues');
                    }
                });
            });
            //When clicking on any item that sets the style
            ctoolbar.find('.set-item-style').click(function(){
                var btn=$(this);
                var val=btn.attr('data-value').split('-'); //Split ns-[property]-[value]. i.e. ns-size-x4
                ids.forEach(function(id){
                    style=window.appMap.style(type,id,{[val[1]]:val[2]}); //Set [property] to [value] for this app. i.e. size:x4
                    window.appMap.applyStyleClasses(type,id,val[1]); //Update DOM node with the styles
                });
            });
            //When clicking on style toggler
            ctoolbar.find('.toggle-item-style').click(function(){
                var btn=this;
                var values=this.getAttribute('data-values').split(',');
                values[0]=values[0].split('-');
                values[1]=values[1].split('-');
                if(style[values[0][1]]==undefined || style[values[0][1]]=='%?%' || style[values[0][1]]==values[0][2]){
                    var setval=values[1][2];
                }else{
                    var setval=values[0][2];
                }
                ids.forEach(function(id){
                    window.appMap.style(type,id,{[values[0][1]]:setval});
                    window.appMap.applyStyleClasses(type,id); //Update DOM node with the styles
                });
            });
            //Disable object-specific buttons if more than one object selected
            if(selected.length>1){
                ctoolbar.find('[data-id]').addClass('disabled').prop('disabled',true);
            //If one object selected - make them work
            }else{
                //Pass ID to buttons that need it
                ctoolbar.find('[data-id]').attr('data-id',ids.join(','));
                //App-specific buttons
                if(type=='app'){
                    ctoolbar.find('.isolateApplication').on('click',window.appMap.appIsolate);
                    if(window.appMap.isolateAppID!=undefined){
                        ctoolbar.find('.isolateApplication').addClass('active');
                    }
                    ctoolbar.find('.singleApplicationImage').on('click',window.appMap.appOpenImage);
                    ctoolbar.find('.openApplication').on('click',window.appMap.appOpenEditModal);
                    ctoolbar.find('.deleteApplication').on('click',window.appMap.appDelete);
                //Integration-specific buttons
                }else if(type=='connection'){
                    ctoolbar.find(".integrationSettings").click(window.appMap.connectionSettingsModal);
                    ctoolbar.find(".deleteConnection").click(window.appMap.connectionDelete);
                //Area specific buttons
                }else if(type=='area'){
                    ctoolbar.find('.areaSettings').on('click',window.appMap.areaSettingsModal);
                    ctoolbar.find(".deleteArea").click(window.appMap.areaDelete);
                }else if(type=='shape'){
                    ctoolbar.find('.shapeSettings').on('click',window.appMap.shapeSettingsModal);
                    ctoolbar.find(".deleteShape").click(window.appMap.shapeDelete);
                }
            }
        //More than one item selected
        }else if(selected.length>1){
            var seltext=(selcount.apps>0?selcount.apps+' tool'+(selcount.apps>1?'s':'')+", ":'');
            seltext+=(selcount.connections>0?selcount.connections+' connection'+(selcount.connections>1?'s':'')+", ":'');
            seltext+=(selcount.areas>0?selcount.areas+' area'+(selcount.areas>1?'s':'')+", ":'');
            seltext+=(selcount.shapes>0?selcount.shapes+' shape'+(selcount.shapes>1?'s':'')+", ":'');
            window.appMap.toolbar.append("<span style='display:inline-block;margin-left:10px;'>"+(seltext.substring(0,seltext.length-2))+" selected</span>");
        }
        //Make tooltips work
        //window.appMap.toolbar.find('.btn[title]').powerTip({placement:'n',fadeInTime:50,fadeOutTime:50,closeDelay:50});
        //window.appMap.toolbar.find('.btn[title]').tooltip({placement:'top',delay:{show:50,hide:50}});
    },
    //Instantiate align+spacing toolbar
    makeAlignToolbar:function(selcount){
        var alignToolbar=$(".alignOptions").clone().removeClass('d-none');
        var alignElements=window.appMap.root.children(".selected.applicationNode");
        //If less than 3 apps are selected, we hide the spacing options
        if(selcount.apps<3) alignToolbar.find(".spacingOptions").hide();
        else alignToolbar.find(".spacingOptions").show();
        $("body").append(alignToolbar);
        var alignToolbarBBox=alignToolbar[0].getBoundingClientRect();
        //Calculate a box of selected items by cyclsing through all of them
        window.appMap.selBox={top:9999,left:9999,bottom:0,right:0};
        window.appMap.mapBBox=window.appMap.root[0].getBoundingClientRect();
        alignElements.each(function(){
            var box=this.getBoundingClientRect();
            window.appMap.selBox.top=Math.min(window.appMap.selBox.top,box.top-window.appMap.mapBBox.top);
            window.appMap.selBox.left=Math.min(window.appMap.selBox.left,box.left-window.appMap.mapBBox.left);
            window.appMap.selBox.bottom=Math.max(window.appMap.selBox.bottom,box.top+box.height-window.appMap.mapBBox.top);
            window.appMap.selBox.right=Math.max(window.appMap.selBox.right,box.left+box.width-window.appMap.mapBBox.left);
        });
        //Calculate empty space between elements
        window.appMap.selBox.spaceV=window.appMap.selBox.bottom-window.appMap.selBox.top;
        window.appMap.selBox.spaceH=window.appMap.selBox.right-window.appMap.selBox.left;
        alignElements.each(function(){
            var box=this.getBoundingClientRect();
            window.appMap.selBox.spaceV-=box.height;
            window.appMap.selBox.spaceH-=box.width;
        });
        //Position the toolbar on the left top or right top edge of the selection box
        var alignToolbarLeft=window.appMap.selBox.left+window.appMap.mapBBox.left-alignToolbarBBox.width-20;
        if(alignToolbarLeft<0) alignToolbarLeft=window.appMap.selBox.right+window.appMap.mapBBox.left+20;
        alignToolbar.css({
            'top':window.appMap.selBox.top+window.appMap.mapBBox.top+$(window).scrollTop()-20,
            'left':alignToolbarLeft+$(window).scrollLeft()
        });

        //Batch element alignment is all happening within this big crazy function
        function align(type){
            var positionsToSave=[];
            //If distribute is called, we need to do a fist pass to find items that are most close to top, right, bottom and left sides
            if(type.startsWith("distribute") || type.startsWith("space")){
                var extremes={};
                alignElements.each(function(index,obj){
                    obj={
                        obj:$(obj),
                        BBox:obj.getBoundingClientRect(),
                        pos:window.appMap.getPosition($(obj))
                    }
                    alignElements[index].sortingObj=obj;
                    if(extremes.top==undefined){
                        extremes={top:obj,right:obj,bottom:obj,left:obj};
                    }else{
                        if(obj.BBox.y<extremes.top.BBox.y) extremes.top=obj;
                        if(obj.BBox.x+obj.BBox.width>extremes.right.BBox.x+extremes.right.BBox.width) extremes.right=obj;
                        if(obj.BBox.y+obj.BBox.height>extremes.bottom.BBox.y+extremes.bottom.BBox.height) extremes.bottom=obj;
                        if(obj.BBox.x<extremes.left.BBox.x) extremes.left=obj;
                    }
                });
                //Sort list by X or Y position depending on distribution mode
                if(type.startsWith("distributeV") || type.startsWith("spaceV")) alignElements=alignElements.sort(function(a,b){return (a.sortingObj.pos.y<b.sortingObj.pos.y?-1:1)});
                if(type.startsWith("distributeH") || type.startsWith("spaceH")) alignElements=alignElements.sort(function(a,b){return (a.sortingObj.pos.x<b.sortingObj.pos.x?-1:1)});
            }
            //Position elements based on the positioning type
            const alignCount=alignElements.length;
            let positionedCount=0;
            let spacingCount=0; //Only used when spaceV or spaceH is pressed
            alignElements.each(function(index,obj){
                const objBBox=obj.getBoundingClientRect();
                obj=$(obj);
                var pos=window.appMap.getPosition(obj);
                if(type=="alignVL"){
                    pos.x=window.appMap.selBox.left;
                }else if(type=="alignVC"){
                    pos.x=window.appMap.selBox.left+(window.appMap.selBox.right-window.appMap.selBox.left)/2-objBBox.width/2;
                }else if(type=="alignVR"){
                    pos.x=window.appMap.selBox.right-objBBox.width;
                }else if(type=="alignHT"){
                    pos.y=window.appMap.selBox.top;
                }else if(type=="alignVM"){
                    pos.y=window.appMap.selBox.top+(window.appMap.selBox.bottom-window.appMap.selBox.top)/2-objBBox.height/2;
                }else if(type=="alignVB"){
                    pos.y=window.appMap.selBox.bottom-objBBox.height;
                }else if(
                    ((type.startsWith("distributeV") || type.startsWith("spaceV")) && obj[0]!=extremes.top.obj[0] && obj[0]!=extremes.bottom.obj[0]) ||
                    ((type.startsWith("distributeH") || type.startsWith("spaceH")) && obj[0]!=extremes.left.obj[0] && obj[0]!=extremes.right.obj[0])
                ){
                    positionedCount++;
                    if(type=="distributeVT"){
                        var top=extremes.top.pos.y;
                        var bottom=extremes.bottom.pos.y;
                        pos.y=top+((bottom-top)/(alignCount-1))*positionedCount;
                    }else if(type=="distributeVM"){
                        var top=extremes.top.pos.y+extremes.top.BBox.height/2;
                        var bottom=extremes.bottom.pos.y+extremes.bottom.BBox.height/2;
                        pos.y=top+((bottom-top)/(alignCount-1))*positionedCount-objBBox.height/2;
                    }else if(type=="distributeVB"){
                        var top=extremes.top.pos.y+extremes.top.BBox.height;
                        var bottom=extremes.bottom.pos.y+extremes.bottom.BBox.height;
                        pos.y=top+((bottom-top)/(alignCount-1))*positionedCount-objBBox.height;
                    }else if(type=="distributeHL"){
                        var left=extremes.left.pos.x;
                        var right=extremes.right.pos.x;
                        pos.x=left+((right-left)/(alignCount-1))*positionedCount;
                    }else if(type=="distributeHC"){
                        var left=extremes.left.pos.x+extremes.left.BBox.width/2;
                        var right=extremes.right.pos.x+extremes.right.BBox.width/2;
                        pos.x=left+((right-left)/(alignCount-1))*positionedCount-objBBox.width/2;
                    }else if(type=="distributeHR"){
                        var left=extremes.left.pos.x+extremes.left.BBox.width;
                        var right=extremes.right.pos.x+extremes.right.BBox.width;
                        pos.x=left+((right-left)/(alignCount-1))*positionedCount-objBBox.width;
                    }else if(type=="spaceV"){
                        spacingCount+=window.appMap.selBox.spaceV/(alignCount-1);
                        pos.y=extremes.top.pos.y+extremes.top.BBox.height+spacingCount;
                        spacingCount+=objBBox.height;
                    }else if(type=="spaceH"){
                        spacingCount+=window.appMap.selBox.spaceH/(alignCount-1);
                        pos.x=extremes.left.pos.x+extremes.left.BBox.width+spacingCount;
                        spacingCount+=objBBox.width;
                    }
                }
                window.appMap.setPosition(obj,pos.x,pos.y);
                //Add position for saving
                positionsToSave.push({
                    id:obj.attr('data-appid'),
                    top:pos.y,
                    left:pos.x
                });
            });
            //Update lines
            svgline.prototype.updateLinesSpread();
            //Save positions
            window.appMap.savePositions(positionsToSave);
            //Recreate the toolbar
            window.appMap.createMainToolbar();
        }

        alignToolbar.find(".alignVL,.alignVC,.alignVR,.alignHT,.alignVM,.alignVB").click(function(){
            var type=this.className.split(/\s+/).find(e=>e.startsWith("align"));
            align(type);
        });
        alignToolbar.find(".distributeVT,.distributeVM,.distributeVB,.distributeHL,.distributeHC,.distributeHR").click(function(){
            var type=this.className.split(/\s+/).find(e=>e.startsWith("distribute"));
            align(type);
        });
        alignToolbar.find(".spaceV,.spaceH").click(function(){
            var type=this.className.split(/\s+/).find(e=>e.startsWith("space"));
            align(type);
        });
    },
    //Sets or gets the style for chosen object
    style:function(type,id,obj){
        //If multiple ids are set, create a composite style
        if(Array.isArray(id) && id.length>1){
            let style={ids:[]};
            id.forEach(function(itemid,key){
                var tstyle=window.appMap.style(type,itemid);
                for(const property in tstyle){
                    if(property=='id'){
                         style.ids.push(tstyle[property]);
                         continue;
                    }
                    if(type=='area' && ["x","y","width","height","title","description"].includes(property))  continue;
                    //If we found this property for the first time and it's not set
                    if(key==0){
                        style[property]=tstyle[property];
                    //If this property was not set in first style or is different
                    }else if(style[property]==undefined || style[property]!=tstyle[property]){
                        style[property]="%?%";
                    }
                }
                //Check if every property of composite style exists in this tstyle
                for(const property in style){
                    if(["id","ids"].includes(property) || (type=='area' && ["x","y","width","height","title","description"].includes(property))) continue;
                    if(tstyle[property]==undefined){
                        style[property]="%?%";
                    }
                }
            });
            return style;
        }else{
            if(Array.isArray(id)) id=id[0];
            if(window.appMap.map_style==undefined) window.appMap.map_style={};
            if(window.appMap.map_style[type+'s']==undefined) window.appMap.map_style[type+'s']=[];
            var key=window.appMap.map_style[type+'s'].findIndex(function(item){return item.id==id;});
            if(key===-1){ //Using old appID and changing it to id. This whole block will be obsolete when the old maps get changed
                key=window.appMap.map_style[type+'s'].findIndex(function(item){return item.appID==id;});
                if(key!==-1){
                    window.appMap.map_style[type+'s'][key].id=window.appMap.map_style[type+'s'][key].appID;
                    window.appMap.map_style[type+'s'][key].appID=undefined;
                }
            }
            if(key===-1) key=window.appMap.map_style[type+'s'].push({id:id})-1; //If app/connection doesnt exist, create it
            if(obj!==undefined){
                Object.assign(window.appMap.map_style[type+'s'][key],obj);
                window.appMap.saveMapStyle(); //Triggers a delayed save function
            }
            return window.appMap.map_style[type+'s'][key];
        }
    },
    //Convert saved style into css classes and add them to app/integration
    applyStyleClasses:function(type,id,propertyChanged){
        if(type=='app'){
            var element=window.appMap.appdata.find(function(item){return item.id==id;}).element;
            var prefix='ns';
        }else if(type=='connection'){
            var element=window.appMap.integrations.find(function(item){return item.id==id;}).svgline.svg;
            var prefix='cs';
        }else if(type=='area'){
            var element=window.appMap.rootDOM.querySelector(".mapArea[data-areaid='"+id+"']");
            var prefix='as';
        }else if(type=='shape'){
            var element=window.appMap.rootDOM.querySelector(".mapShape[data-shapeid='"+id+"']");
            var prefix='ss';
        }
        var style=window.appMap.style(type,id);
        //Remove all prefix-* classes
        element.setAttribute('class',element.getAttribute('class').replace(new RegExp("\\b"+prefix+"-[a-zA-Z0-9\\-]+\\b","g"),''));
        for(const property in style){
            if(property=='id') continue;
            if(['area','shape'].includes(type) && ["x","y","width","height","title","description","integrationID","lineOrder"].includes(property))  continue;
            element.addClass(prefix+'-'+property+'-'+style[property]);
        }
        //If toolbar exists, recreate it. Right now it's easier to create it from scratch instead of updating the labels
        if(window.appMap.toolbar!=undefined && window.appMap.toolbar.find('.'+type+'Tools').length>0) window.appMap.createMainToolbar();
        //Update lines
        if(type=='app'){
            var app=window.appMap.appdata.find(function(item){return item.id==id;});
            if(app.svglines!=undefined) svgline.prototype.updateLinesSpread();
        }
        if(type=='connection'){
            var integration=window.appMap.integrations.find(function(item){return item.id==id;});
            //Set the same classes to description
            if(integration.descriptionElement!=undefined){
                integration.descriptionElement.setAttribute('class',integration.descriptionElement.getAttribute('class').replace(new RegExp("\\b"+prefix+"-[a-zA-Z0-9\\-]+\\b","g"),''));
                for(const property in style){
                    integration.descriptionElement.addClass(prefix+'-'+property+'-'+style[property]);
                }
            }
            //Set the same classes to faqs
            if(Array.isArray(integration.faqElements)){
                integration.faqElements.forEach(faq=>{
                    faq.setAttribute('class',faq.getAttribute('class').replace(new RegExp("\\b"+prefix+"-[a-zA-Z0-9\\-]+\\b","g"),''));
                    for(const property in style){
                        faq.addClass(prefix+'-'+property+'-'+style[property]);
                    }
                });
            }
            if(integration.svgline.svg.getAttribute('class').match(new RegExp("\\bcs-shape-curve\\b","g"))){
                integration.svgline.shape='curve';
            }else if(integration.svgline.svg.getAttribute('class').match(new RegExp("\\bcs-shape-straight\\b","g"))){
                integration.svgline.shape='straight';
            }else if(integration.svgline.svg.getAttribute('class').match(new RegExp("\\bcs-shape-elbow\\b","g"))){
                integration.svgline.shape='elbow';
            }
            integration.svgline.forceUpdate=true;
            svgline.prototype.updateLinesSpread();
        }
        if(type=='shape' && propertyChanged!=undefined && propertyChanged=='type'){
            element.querySelector("svg").remove();
            element.prepend(window.appMap.getShapeSVG(style.type));
            let svg=element.querySelector("svg");
            /*
            proportion=parseFloat(svg.getAttribute('width'))/parseFloat(svg.getAttribute('height'));
            let originalRect=element.getBoundingClientRect();
            let mapRect=window.appMap.rootDOM.getBoundingClientRect();
            newRect={
                left:originalRect.left-mapRect.x,
                top:originalRect.top-mapRect.y,
                width:originalRect.width,
                height:originalRect.width/proportion
            }
            window.appMap.setPosition(element,newRect.left,newRect.top);
            element.style.width=newRect.width+"px";
            element.style.height=newRect.height+"px";
            */
        }
        return style;
    },
    addSavingSpinner:function(){
        let spinner=window.appMap.rootDOM.parentNode.querySelector(".appMapSaving");
        if(spinner) spinner.remove();
        spinner=document.createElementFromHTML("<i class='appMapSaving fas fa-sync fa-2x fa-spin'></i>");
        window.appMap.rootDOM.parentNode.prepend(spinner);
        let scrollable=document.querySelector(".appContainerScrollable");
        if(scrollable){
            let sRect=scrollable.getBoundingClientRect();
            let spinRect=spinner.getBoundingClientRect();
            spinner.style.position="fixed";
            spinner.style.left=(sRect.x+sRect.width-spinRect.width-25)+"px";
            spinner.style.top=(sRect.y+15)+"px";
            spinner.style.right="initial";
        }
    },
    //Save ALL styles into db. This function has a builtin delay with reset
    saveMapStyle:function(type){
        if(window.appMap.mapStyleSaveTimeout!=undefined){
            clearTimeout(window.appMap.mapStyleSaveTimeout);
        }
        window.appMap.mapStyleSaveTimeout=setTimeout(function(){
            if(window.appMap.saveMapStyleXHR!=undefined) window.appMap.saveMapStyleXHR.abort();
            window.appMap.addSavingSpinner();
            window.appMap.saveMapStyleXHR=$.ajax({
                type:'POST',
                url:'/organization/'+window.appMap.organization_id+'/updateMapStyle',
                data:{map_style:JSON.stringify(window.appMap.map_style)},
                datatype:'json',
                headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
                error:function(){
                    window.appMap.root.parent().find(".appMapSaving").addClass('text-danger').fadeOut(1000,function(){$(this).remove();});
                },
                success:function(){
                    window.appMap.root.parent().find(".appMapSaving").addClass('text-success').fadeOut(100,function(){$(this).remove();});
                }
            });
        },700);
        window.appMap.addUndoState(type);
    },
    //Save positions of one or multiple apps on the map
    savePositions:function(positions){
        if(window.appMap.savePositionsTimeout!=undefined){
            clearTimeout(window.appMap.savePositionsTimeout);
        }
        window.appMap.savePositionsTimeout=setTimeout(function(){
            if(window.appMap.savePositionsXHR!=undefined) window.appMap.savePositionsXHR.abort();
            window.appMap.addSavingSpinner();
            window.appMap.savePositionsXHR=$.ajax({
                type:'POST',
                url:'/organization/'+window.appMap.organization_id+'/updateMapPositions',
                data:{positions:positions},
                datatype:'json',
                headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
                error:function(){
                    window.appMap.root.parent().find(".appMapSaving").addClass('text-danger').fadeOut(1000,function(){$(this).remove();});
                },
                success:function(){
                    window.appMap.root.parent().find(".appMapSaving").addClass('text-success').fadeOut(100,function(){$(this).remove();});
                }
            });
        },700);
        window.appMap.addUndoState('positions');
    },
    addUndoState:function(type){
        if(type==undefined) type="general";
        //If there's an unde state that was made less than a fraction of a second ago, we remove it and replace it with a new one
        if(
            window.appMap.undoStates.length>1 && 
            Date.now()-window.appMap.undoStates[window.appMap.undoStates.length-1].time<500 &&
            window.appMap.undoStates[window.appMap.undoStates.length-1].type==type
        ){
            window.appMap.undoStates.pop();
        }
        let state={
            time:Date.now(),
            type:type,
            style:JSON.parse(JSON.stringify(window.appMap.map_style)),
            positions:[]
        };
        window.appMap.root.children(".applicationNode").each(function(){
            const obj=$(this);
            var pos=window.appMap.getPosition(obj);
            state.positions.push({
                id:obj.attr('data-appid'),
                top:pos.y,
                left:pos.x
            });
        });
        window.appMap.undoStates.push(state);
        if(window.appMap.undoStates.length>30) window.appMap.undoStates.shift();
    },
    undo:function(){
        if(window.appMap.undoStates.length>1){
            const prevState=JSON.parse(JSON.stringify(window.appMap.undoStates.pop()));
            const state=JSON.parse(JSON.stringify(window.appMap.undoStates.pop()));
            //Restore positions
            state.positions.forEach(function(position){
                const obj=window.appMap.root.find(".applicationNode[data-appid="+position.id+"]");
                window.appMap.setPosition(obj,position.left,position.top);
            });
            svgline.prototype.updateLinesSpread();
            //Restore styles
            window.appMap.map_style=JSON.parse(JSON.stringify(state.style));
            //Map style
            window.appMap.updateGrid(window.appMap.map_style.map.gridSize);
            window.appMap.root.parent().css('width',window.appMap.map_style.map.width+"px");
            window.appMap.root.parent().css('height',window.appMap.map_style.map.height+"px");
            //Restore apps
            window.appMap.root.find(".applicationNode").each(function(){
                window.appMap.applyStyleClasses('app',this.getAttribute('data-appid'));
            });
            //Restore connections
            window.appMap.root.find(".mapArrow").each(function(){
                window.appMap.applyStyleClasses('connection',this.getAttribute('data-intid'));
            });
            //restore area styles
            window.appMap.root.find(".mapArea").each(function(){
                var object=$(this);
                var areaid=this.getAttribute('data-areaid');
                var style=window.appMap.map_style.areas.find(function(item){return item.id==areaid});
                window.appMap.setPosition(object,style.x,style.y);
                object.css('width',style.width+"px");
                object.css('height',style.height+"px");
                window.appMap.applyStyleClasses('area',areaid);
            });
            //restore shape styles
            window.appMap.root.find(".mapShape").each(function(){
                var object=$(this);
                var shapeid=this.getAttribute('data-shapeid');
                var style=window.appMap.map_style.shapes.find(function(item){return item.id==shapeid});
                window.appMap.setPosition(object,style.x,style.y);
                object.css('width',style.width+"px");
                object.css('height',style.height+"px");
                window.appMap.applyStyleClasses('shape',shapeid,'type');
            });
            //Refresh toolbar and save style and positions
            window.appMap.createMainToolbar();
            //Decide what to save. Saving both will create two undo steps
            if(prevState.type=='positions'){
                window.appMap.savePositions(state.positions);
            }else{
                window.appMap.saveMapStyle(prevState.type);
            }
        }
    },
    //Add app to map modal
    addApplicationToMap:function(){
        //Create modal
        var modal= bootstrap.Modal.getInstance(document.getElementById('addApplicationToMapModal'), {keyboard: false, focus:true});
        const newContainerId = "addApplicationToMapModal";
        const modalRef = document.querySelector('#'+newContainerId);

        modal.show();

        modalRef.addEventListener("hidden.bs.modal",function(){
            var modalDom = document.getElementById(newContainerId);
            if (modalDom) {
                console.log(newContainerId+" modal removed");
            }
            var modal = bootstrap.Modal.getInstance(modalRef);
            if (modal) {
                modal.dispose();
                console.log(newContainerId+" bootstrap modal disposed");
            }
        });

        modalRef.addEventListener("shown.bs.modal",function(){
            console.log(newContainerId+" shown");
            $('#addApplicationToMapModal').find(".loadingHA").remove();
            var loading=$("<div class='position-absolute bg-white'><div class='spinner-border' role='status'><span class='visually-hidden'>Loading...</span></div></div>");
            loading.css({
                position:"absolute",
                top:$('.hidden_applications').position().top+"px",
                left:$('.hidden_applications').position().left+"px",
                width:$('.hidden_applications').width(),
                height:$('.hidden_applications').height(),
                'z-index':99999
            });
            $(".hidden_applications").after(loading);
            $.ajax({
                url:'/organization/'+window.appMap.organization_id+'/listHiddenApplications',
                success:function(response){
                    console.log(newContainerId+" listHiddenApplications succeeded");
                    $(".hidden_applications").html(response);
                    loading.remove();
                    $(".hidden_applications").off();
                    //Update count of hidden apps
                    $(".hidden_apps_count").html($(".hidden_applications .applicationNode").length);
                    //Let suer drag the apps to the map
                    $(".hidden_applications .applicationNode").each(function(element){
                        window.appMap.makeHiddenAppDraggable($(this));
                        console.log(element+" activated draggable");
                    });
                },
                error:function(xhr,status,error){
                    loading.find('.text').html("Error loading data. Please retry.");
                },
                complete:function() {
                }
            });

            setTimeout(function(){$('#addApplicationToMapModal').find("#application").focus();},500);
            if($('#addApplicationToMapModal').find("input#application").val().length>=3){
                $('#addApplicationToMapModal').find("input#application").trigger('keyup');
            }
        });
    },
    //Make hidden app draggable so you can drag it from the hidden apps area to the map
    makeHiddenAppDraggable:function(appObject){
        appObject.draggable({
            appendTo:"body",
            revert:function(e){
                var appNode=$(this);
                if(window.appMap.draggingAppOntoMapAllow){
                    var appBBox=appNode[0].getBoundingClientRect();
                    var mapBBox=window.appMap.root[0].getBoundingClientRect();
                    var position={x:appBBox.x-mapBBox.x,y:appBBox.y-mapBBox.y};
                    //Place app on the map
                    window.appMap.addApp(appNode.prop('outerHTML'),undefined,position);
                    //Send ajax request to update hidden status
                    $.ajax({
                        type:'post',
                        url:'/organization/'+window.appMap.organization_id+'/showApplications',
                        data:{appIDs:[appNode.attr('data-appid')]},
                        headers:{'X-CSRF-Token':$('meta[name="csrf-token"]').attr('content')},
                        datatype:'json'
                    });
                    //Send request to save position of the app on the map
                    var object=window.appMap.appdata[window.appMap.appdata.length-1].object;
                    var pos=window.appMap.getPosition(object);
                    window.appMap.savePositions([{
                        id:appNode.attr('data-appid'),
                        top:pos.y,
                        left:pos.x
                    }]);

                    //Update hidden apps counter
                    var hiddenAppCount=parseInt($(".hidden_apps_count").html())-1;
                    if(hiddenAppCount<0) hiddenAppCount=0;
                    $(".hidden_apps_count").html(hiddenAppCount);

                    appNode.remove();
                    return false;
                }else{
                    $(".appContainerResizeable").css("z-index",'');
                    return true;
                }
            },
            start:function(e){
                var appNode=this;
                window.appMap.draggingAppOntoMap=true;
                window.appMap.draggingAppOntoMapAllow=false;
                $(".addApplicationToMapModal").css("opacity",1);
                $(".appContainerResizeable").css("z-index",1041);
            },
            drag:function(e){
                var appNode=$(this);
                var modalContent=appNode.closest(".modal-content");
                var mapBBox=window.appMap.root[0].getBoundingClientRect();
                var modalBBox=modalContent[0].getBoundingClientRect();
                var cursor=window.appMap.cursor(e);
                if(cursor.x>mapBBox.x && cursor.x<mapBBox.right && cursor.y>mapBBox.y && cursor.y<mapBBox.bottom){
                    if(cursor.x>modalBBox.x && cursor.x<modalBBox.right && cursor.y>modalBBox.y && cursor.y<modalBBox.bottom){
                        window.appMap.draggingAppOntoMapAllow=false;
                        window.appMap.root.css("box-shadow","none");
                    }else{
                        window.appMap.draggingAppOntoMapAllow=true;
                        window.appMap.root.css("box-shadow","inset 0 0 10px #000000");
                    }
                }else{
                    window.appMap.draggingAppOntoMapAllow=false;
                    window.appMap.root.css("box-shadow","none");
                }
            },
            stop:function(e){
                var appNode=this;
                window.appMap.draggingAppOntoMap=undefined;
                window.appMap.draggingAppOntoMapAllow=undefined;
                window.appMap.root.css("box-shadow","none");
                $(".appContainerResizeable").css("z-index",'');
            }
        });
    },

    //App menu items
    appIsolate:function(e){
        let appdata=window.appMap.appdata;
        if(typeof e=='object'){
            if(e.target.hasClass('applicationNode')){
                var appNode=$(e.target);
            }else{
                var appID=e.target.getAttribute('data-id');
                if(!appID) appID=$(e.target).closest('[data-id]')[0].getAttribute('data-id');
                var appNode=window.appMap.root.find('.applicationNode[data-appid='+appID+']');
            }
            var id=parseInt(appNode.attr('data-appid'));
        }else{
            var id=e;
        }
        if(window.appMap.one_app_mode){
            var highlightClass='';
            var fadeoutClass='hide';
        }else{
            var highlightClass='highLight';
            var fadeoutClass='fadeOut';
        }
        if(window.appMap.isolateAppID==id){
            window.appMap.appUnIsolate();
        }else{
            window.appMap.isolateAppID=id;
            //find app to isolate
            var app=appdata[appdata.findIndex(function(item){return item.id==id})];
            //get all integrations
            var integrations=window.appMap.integrations;
            //add class to app we isolate
            app.object.addClass(highlightClass);
            //find which apps are connected the isolated one
            for(var k=0;k<integrations.length;k++){
                try{
                    let appFrom=appdata.find(function(app){return app.id==integrations[k].appID;});
                    appFrom.connections++
                    appFrom.connectedIDs.push(parseInt(integrations[k].appIDtarget));
                    appFrom.connectionIDs.push(parseInt(integrations[k].id));
                    let appTo=appdata.find(function(app){return app.id==integrations[k].appIDtarget;});
                    appTo.connections++
                    appTo.connectedIDs.push(parseInt(integrations[k].appID));
                    appTo.connectionIDs.push(parseInt(integrations[k].id));
                }catch(error){}
            }
            appdata.sort((a,b)=>(a.connections<b.connections)?1:(a.connections>b.connections?-1:0));

            //Fade out apps
            for(let i=0;i<appdata.length;i++){


                if(appdata[i].id!=id && $.inArray(appdata[i].id,app.connectedIDs)<0){
                    appdata[i].object.addClass(fadeoutClass);
                }else{
                    appdata[i].object.removeClass(fadeoutClass);
                }
                if(appdata[i].id!=id) appdata[i].object.removeClass(highlightClass);


            }
            //Fade out arrows and descriptions
            $(".mapArrow,.connectionDescription").each(function(){
                var element=$(this);
                var intID=element.attr('data-intid');
                var integration=window.appMap.integrations.find(function(item){return item.id==intID});
                if(integration.appID!=id && integration.appIDtarget!=id){
                    element.addClass(fadeoutClass);
                }else{
                    element.removeClass(fadeoutClass);
                }
            });
            $(".mapArea").addClass(fadeoutClass);
            //Update toolbar button
            if(window.appMap.toolbar instanceof jQuery){
                window.appMap.toolbar.find('.isolateApplication').addClass('active');
            }
            $(".mapShape").addClass(fadeoutClass);
            //Update toolbar button
            if(window.appMap.toolbar instanceof jQuery){
                window.appMap.toolbar.find('.isolateApplication').addClass('active');
            }
        }
    },
    appUnIsolate:function(){
        window.appMap.isolateAppID=undefined;
        window.appMap.root.children('.fadeOut,.highLight').removeClass('fadeOut highLight');
        window.appMap.toolbar.find('.isolateApplication').removeClass('active');
    },
    appOpenImage:function(e){
        var appID=e.target.getAttribute('data-id');
        if(!appID) appID=$(e.target).closest('[data-id]')[0].getAttribute('data-id');
        window.open('/mapImage/'+window.appMap.organization_uniqid+'?appid='+appID,'_blank');
    },
    appOpenEditModal:function(e){
        //Get app id either from the toolbar button or application node depending on what was clicked
        const btnEl=e.target.closest('.btn');
        const appEl=e.target.closest('.applicationNode');
        let appID = '';
        if(btnEl) appID=btnEl.getAttribute('data-id');
        else if(appEl) appID=appEl.getAttribute('data-appid');
        else return false; //We don't have any app id
        //Show app edit modal
        ajaxModal('organization.applications.edit',[window.appMap.organization_id,appID]);
    },
    appDelete:function(e){
        var appID=e.target.getAttribute('data-id');
        if(!appID) appID=$(e.target).closest('[data-id]')[0].getAttribute('data-id');
        var appkey=window.appMap.appdata.findIndex(function(item){return item.id==appID;});
        var app=window.appMap.appdata[appkey];
        confirmModal("This will hide "+app.object.text().trim()+" from this map. Proceed?",e=>window.appMap.appsHide([app.id]));
    },
    appsHide:function(appIDs){

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'DELETE',
            url:'/organization/'+window.appMap.organization_id+'/hideApplications',
            data:{appIDs:appIDs, "_method": 'DELETE'},
            datatype:'json',
            beforeSend:function(){
                appIDs.forEach(function(appID){
                    var app=window.appMap.appdata.find(function(item){return item.id==appID;});
                    app.object.addClass('fadeOut');
                    if(app.svglines!=undefined){
                        for(let i=0;i<app.svglines.length;i++){
                            app.svglines[i].path.addClass('fadeOut');
                            if(app.svglines[i].integration.descriptionElement!=undefined){
                                app.svglines[i].integration.descriptionElement.addClass('fadeOut');
                            }
                        }
                    }
                });
            },
            success:function(data,status,xhr){
                if(data.success){
                    //Increment counter
                    $(".hidden_apps_count").html(parseInt($(".hidden_apps_count").html())+appIDs.length);
                    //Clear SVG lines before modifying array behind them, force clearing faded out lines
                    window.appMap.clearLines(true);
                    //Go though each deleted app
                    appIDs.forEach(function(appID){
                        var appkey=window.appMap.appdata.findIndex(function(item){return item.id==appID;});
                        var app=window.appMap.appdata[appkey];
                        //Remove integrations from an array based on which integrations are connected to the app we deleted
                        if(app.connectionIDs!=undefined){
                            for(var i=0;i<app.connectionIDs.length;i++){
                                var conID=app.connectionIDs[i];
                                var conKey=window.appMap.integrations.findIndex(function(item){return item.id==conID;});
                                window.appMap.integrations.splice(conKey,1);
                            }
                        }
                        //Remove app object
                        app.object.remove();
                        //Remove app data for this app
                        window.appMap.appdata.splice(appkey,1);
                    });
                    //Update appdata based on new integrations
                    window.appMap.updateIntegrations(window.appMap.integrations);
                    //Redraw the lines based on updated integrations array
                    window.appMap.redrawLines();
                }else{
                    //Restore faded out items back
                    appIDs.forEach(function(appID){
                        var app=window.appMap.appdata.find(function(item){return item.id==appID;});
                        app.object.removeClass('fadeOut');
                        if(app.svglines!=undefined){
                            for(i=0;i<app.svglines.length;i++){
                                app.svglines[i].path.removeClass('fadeOut');
                                app.svglines[i].integration.descriptionElement.removeClass('fadeOut');
                            }
                        }
                    });
                }
            },
            error:function(xhr,status,error){
                //Restore faded out items back
                appIDs.forEach(function(appID){
                    var app=window.appMap.appdata.find(function(item){return item.id==appID;});
                    app.object.removeClass('fadeOut');
                    if(app.svglines!=undefined){
                        for(i=0;i<app.svglines.length;i++){
                            app.svglines[i].path.removeClass('fadeOut');
                            app.svglines[i].integration.descriptionElement.removeClass('fadeOut');
                        }
                    }
                });
            }
        });
    },
    appsListHidden:function(){
        var modal=$("#modalLoading").clone().attr('id','hiddenAppsModal');
        console.log(modal);
        modal.find(".modal-dialog").css("width",'400px');
        $('body').append(modal);
        modal.show();
        modal.on("hidden.bs.modal",function(){
            //modal.modal('dispose'); //Throws an error for some reason
            modal.remove();
        });
        content=modal.find(".modal-content");
        $.ajax({
            url:'/organization/'+window.appMap.organization_id+'/listHiddenApplications',
            success:function(response){
                content.html(response);
                content.find(".applicationNode").click(function(){
                    var appid=$(this).attr('data-appid');
                    var html=$(this).prop('outerHTML');
                    $(".hidden_apps_count").html(parseInt($(".hidden_apps_count").html())-1);
                    window.appMap.addApp(html); //Place app on the map
                    //Send ajax request to update hidden status
                    $.ajax({
                        type:'post',
                        url:'/organization/'+window.appMap.organization_id+'/showApplications',
                        data:{appIDs:[appid]},
                        headers:{'X-CSRF-Token':$('meta[name="csrf-token"]').attr('content')},
                        datatype:'json'
                    });
                    //Send request to save position of the app on the map
                    var object=window.appMap.appdata[window.appMap.appdata.length-1].object;
                    var pos=window.appMap.getPosition(object);
                    window.appMap.savePositions([{
                        id:appid,
                        top:pos.y,
                        left:pos.x
                    }]);
                    modal.hide();
                });
            },
            error:function(xhr,status,error){
                content.html("<div style='text-align:center;padding:50px'>Error loading data.</div>");
            }
        });
    },
    //Open connection settings modal
    connectionSettingsModal:function(e){
        let btnEl=e.target.closest('.btn');
        let arrowEl=e.target.closest('.mapArrow');
        let descEl=e.target.closest('.connectionDescription');
        let intID = '';
        if(btnEl) intID=btnEl.getAttribute('data-id');
        else if(arrowEl) intID=arrowEl.getAttribute('data-intid');
        else if(descEl) intID=descEl.getAttribute('data-intid');
        else return false;
        var intkey=window.appMap.integrations.findIndex(function(item){return item.id==intID;});
        var route=$(this).attr('data-route');
        //Load integration edit modal
        ajaxModal('integrations.create',[window.appMap.organization_id,window.appMap.integrations[intkey].appID],{id:intID});
    },
    //Connection menu items
    connectionDelete:function(e){
        console.log(e);
        var intID=e.target.getAttribute('data-id');
        if(!intID) intID=$(e.target).closest('[data-id]')[0].getAttribute('data-id');
        var intkey=window.appMap.integrations.findIndex(function(item){return item.id==intID;});
        var integration=window.appMap.integrations[intkey];
        confirmModal("This will remove selected connection. Proceed?",function(){
            window.appMap.deselectEverything();
            $.ajax({
                type:'post',
                url:'/organization/'+window.appMap.organization_id+'/application/'+integration.appID+'/integrations/delete',
                data:{id:intID},
                headers:{'X-CSRF-Token':$('meta[name="csrf-token"]').attr('content')},
                datatype:'json',
                beforeSend:function(){
                    $(integration.svgline.path).addClass('fadeOut');
                },
                success:function(data,status,xhr){
                    if(data.success){
                        window.appMap.clearLines(true); //Clear SVG lines before modifying array behind them, force clearing faded out lines
                        window.appMap.integrations.splice(intkey,1); //Remove integration from the list
                        window.appMap.updateIntegrations(window.appMap.integrations); //Update appdata based on modified integrations list
                        window.appMap.redrawLines(); //Redraw the lines based on updated integrations list
                    }else{
                        $(integration.svgline.path).removeClass('fadeOut');
                    }
                },
                error:function(xhr,status,error){
                    $(integration.svgline.path).removeClass('fadeOut');
                }
            });
        });
    },

    areaSettingsModal:function(e){
        //Get area id from a button we clicked or an area we double clicked
        let areaid;
        let btnEl=e.target.closest('.btn');
        let areaEl=e.target.closest('.mapArea');
        if(btnEl) areaid=btnEl.getAttribute('data-id');
        else if(areaEl) areaid=areaEl.getAttribute('data-areaid');
        else return false; //We don't have any area id
        //Get key and object
        var areakey=window.appMap.map_style.areas.findIndex(function(item){return item.id==areaid;});
        var area=window.appMap.map_style.areas[areakey];

        //get some vars in place
        var newContainerId = "areaModal";
        var container=$("#modalLoading").clone().attr('id',newContainerId);

        //append to body
        $('body').append(container);

        //Create modal
        var modal = bootstrap.Modal.getOrCreateInstance(container);

        const modalRef = document.querySelector('#'+newContainerId);
        modalRef.addEventListener("hidden.bs.modal",function(){
            var modalDom = document.getElementById(newContainerId);
            if (modalDom) {
                modalDom.remove();
                console.log(newContainerId+" modal container removed from DOM");
            }
            var modal = bootstrap.Modal.getInstance(modalRef);
            if (modal) {
                modal.dispose();
                console.log(newContainerId+" bootstrap modal disposed");
            }
        });
        modalRef.addEventListener("shown.bs.modal",function(){
            console.log(newContainerId+" modal shown");
        });

        modal.show();

        var content=container.find(".modal-content");
        content.html($(document.querySelector(".areaModalContent")).clone().removeClass("d-none"));
        content.find("input[name=title]").val(area.title);
        content.find("textarea[name=description]").val(area.description);
        setTimeout(function(){content.find("input[name=title]").focus();},500);
        content.find('.btn-success').on('click',function(){
            console.log("Saving areaModal...");
            var style={
                title:content.find('input[name=title]').val(),
                description:content.find('textarea[name=description]').val()
            };
            window.appMap.root.find('.mapArea[data-areaid='+areaid+'] .title').html(style.title?'<span>'+style.title+'</span>':' ');
            window.appMap.root.find('.mapArea[data-areaid='+areaid+'] .description').html(style.description?'<span>'+style.description+'</span>':' ');
            window.appMap.style('area',areaid,style);
            window.appMap.saveMapStyle();
            console.log("Saved areaModal.");
        });
        setTimeout(function(){content.find('input[name=title]').focus();},700);
    },
    //Area menu items
    areaDelete:function(e){
        var areaid=e.target.getAttribute('data-id');
        if(!areaid) areaid=$(e.target).closest('[data-id]')[0].getAttribute('data-id');
        var areakey=window.appMap.map_style.areas.findIndex(function(item){return item.id==areaid;});
        var area=window.appMap.map_style.areas[areakey];
        confirmModal("This will remove selected area. Proceed?",function(){
            window.appMap.deselectEverything();
            window.appMap.root.find('.mapArea[data-areaid='+areaid+']').remove();
            window.appMap.map_style.areas.splice(areakey,1);
            window.appMap.saveMapStyle();
            console.log(areaid+" area deleted.");
        });
    },

    shapeSettingsModal:function(e){
        //Get area id from a button we clicked or an area we double clicked
        let shapeid;
        let btnEl=e.target.closest('.btn');
        let shapeEl=e.target.closest('.mapShape');
        if(btnEl) shapeid=btnEl.getAttribute('data-id');
        else if(shapeEl) shapeid=shapeEl.getAttribute('data-shapeid');
        else return false; //We don't have any shape id
        //Get key and object
        var shapekey=window.appMap.map_style.shapes.findIndex(function(item){return item.id==shapeid;});
        var shape=window.appMap.map_style.shapes[shapekey];

        //get some vars in place
        var newContainerId = "shapeModal";
        var container=$("#modalLoading").clone().attr('id',newContainerId);

        //append to body
        $('body').append(container);

        //Create modal
        var modal = new bootstrap.Modal(container);

        const modalRef = document.querySelector('#'+newContainerId);
        modalRef.addEventListener("hidden.bs.modal",function(){
            var modalDom = document.getElementById(newContainerId);
            if (modalDom) {
                modalDom.remove();
                console.log(newContainerId+" modal container removed from DOM");
            }
            var modal = bootstrap.Modal.getInstance(modalRef);
            if (modal) {
                modal.dispose();
                console.log(newContainerId+" bootstrap modal disposed");
            }
        });
        modalRef.addEventListener("shown.bs.modal",function(){
            console.log(newContainerId+" modal shown");
        });

        modal.show();

        var content=$(modalRef).find(".modal-content");
        content.html($(".shapeModalContent").clone().removeClass('d-none'));
        content.find("input[name=title]").val(shape.title);
        content.find("textarea[name=description]").val(shape.description);
        //Fill integration select
        let integrationSelect=content[0].querySelector("select[name=integration]");
        let firstOption=integrationSelect.children[0];
        let optionsToAdd=[];
        window.appMap.integrations.forEach(integration=>{
            var from=window.appMap.appdata.find(item=>{return item.id==integration.appID});
            var to=window.appMap.appdata.find(item=>{return item.id==integration.appIDtarget});
            if(from!=undefined && from.element!=undefined && to!=undefined && to.element!=undefined){
                let text=from.element.querySelector('.appname').innerText.trim();
                text+=" "+(integration.directionID=='0'?"⇾":integration.directionID=='1'?"⇽":"⇿")+" ";
                text+=to.element.querySelector('.appname').innerText.trim();
                //Add options to an array
                optionsToAdd.push({'value':integration.id,'innerHTML':text});
            }
        });
        //Sort
        optionsToAdd.sort((a,b)=>{return (a.innerHTML<b.innerHTML?-1:(a.innerHTML>b.innerHTML?1:0));});
        //Add options to select
        optionsToAdd.forEach(op=>{
            //Clone a new option node and set the values for it
            let option=firstOption.cloneNode(true);
            if(shape.integrationID==op.value) option.setAttribute('selected','selected');
            option.setAttribute('value',op.value);
            option.innerHTML=op.innerHTML;
            integrationSelect.append(option);
        });
        setTimeout(function(){content.find("input[name=title]").focus();},500);
        content.find('.btn-success').on('click',function(){
            console.log("Saving shapeModal...");
            var style={
                title:content.find('input[name=title]').val(),
                description:content.find('textarea[name=description]').val(),
                integrationID:content.find('select[name=integration]').val()
            };
            window.appMap.root.find('.mapShape[data-shapeid='+shapeid+'] .title').html(style.title?'<span>'+style.title+'</span>':'');
            window.appMap.root.find('.mapShape[data-shapeid='+shapeid+'] .description').html(style.description?'<a class="shapePopover text-reset">ⓘ</a>':'');

            window.appMap.style('shape',shapeid,style);
            window.appMap.saveMapStyle();
            window.appMap.redrawLines(); //Redraw the lines based on updated integrations list
        });
        setTimeout(function(){content.find('input[name=title]').focus();},700);
    },
    //Shape menu items
    shapeDelete:function(e){
        var shapeid=e.target.getAttribute('data-id');
        if(!shapeid) shapeid=$(e.target).closest('[data-id]')[0].getAttribute('data-id');
        var shapekey=window.appMap.map_style.shapes.findIndex(function(item){return item.id==shapeid;});
        var shape=window.appMap.map_style.shapes[shapekey];
        confirmModal("This will remove selected shape. Proceed?",function(msg){
            window.appMap.deselectEverything();
            window.appMap.root.find('.mapShape[data-shapeid='+shapeid+']').remove();
            window.appMap.map_style.shapes.splice(shapekey,1);
            window.appMap.saveMapStyle();
            console.log(shapeid+" shape deleted.");
        });
    },

    //When deciding to remove or rename the property, it should be added here,
    //so old blueprints get updated on edit
    legacyStyleReplace:function(style){
        var legacy_replace={
            'ns-text-normal':'ns-bold-off',
            'ns-text-bold':'ns-bold-on',
            'as-fill-on':'as-fillcolor-default',
            'as-fill-off':'as-fillcolor-no',
            'as-color-default':'as-bordercolor-default',
            'as-color-color01':'as-bordercolor-color01',
            'as-color-color02':'as-bordercolor-color02',
            'as-color-color03':'as-bordercolor-color03',
            'as-color-color04':'as-bordercolor-color04',
            'as-color-color05':'as-bordercolor-color05',
            'as-color-color06':'as-bordercolor-color06',
            'as-color-color07':'as-bordercolor-color07',
            'as-color-color08':'as-bordercolor-color08',
            'as-color-color09':'as-bordercolor-color09',
            'as-color-color10':'as-bordercolor-color10',
            'as-color-color11':'as-bordercolor-color11',
            'as-color-color12':'as-bordercolor-color12',
            'as-color-color13':'as-bordercolor-color13',
            'as-color-color14':'as-bordercolor-color14',
            'as-color-color15':'as-bordercolor-color15',
            'as-text':'as-title',
        }
        for(const type in style){
            if(['apps','connections','areas'].includes(type)){
                var prefix=(type=='apps'?'ns':(type=='connections'?'cs':'as'));
                style[type].forEach(function(style){
                    for(const key in legacy_replace){
                        var old=key.split('-');
                        if(old.length==3 && old[0]==prefix && style[old[1]]==old[2]){
                            delete style[old[1]];
                            if(legacy_replace[key].length>0){
                                var newv=legacy_replace[key].split('-');
                                style[newv[1]]=newv[2];
                            }
                        }else if(old.length==2 && old[0]==prefix){
                            var newv=legacy_replace[key].split('-');
                            if(style[old[1]]!=undefined){
                                style[newv[1]]=style[old[1]];
                                delete style[old[1]];
                            }
                        }
                    }
                });
            }
        }
        return style;
    },
    //Set transform position for an element
    setPosition:function(element,x,y){
        if(element instanceof jQuery) element=element[0]; //If jquery object was provided
        x=(x==undefined?0:Math.round(x));
        y=(y==undefined?0:Math.round(y));
        element.style.transform='translate3d('+x+'px,'+y+'px,0)';
        element.style.top=0;
        element.style.left=0;
        element.setAttribute('x',x);
        element.setAttribute('y',y);
    },
    //Get position from transform matrix attribute
    getPosition:function(element){
        if(element instanceof jQuery) element=element[0]; //If jquery object was provided
        var pos={'x':parseFloat(element.getAttribute('x')),'y':parseFloat(element.getAttribute('y'))};
        if(pos.x==null || pos.x.length===0 || pos.y==null || pos.y.length===0){
            var results=element.style.transform.match(/matrix(?:(3d)\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))(?:, (-{0,1}\d+)), -{0,1}\d+\)|\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))\))/);
            if(results && results[1]=='3d') pos={'z':parseFloat(results[2]),'y':parseFlaot(results[3])};
            else if(results) pos={'x':parseFloat(results[5]),'y':parseFloat(results[6])}
        }
        if(pos.x==NaN || pos.x=="NaN") pos.x=0;
        if(pos.y==NaN || pos.y=="NaN") pos.y=0;
        return pos;
    },
    //Set a class for body element to change a cursor for all elements on the page
    setGlobalCursor:function(cursorType){
        if(window.appMap.globalCursor!=cursorType){
            window.appMap.unsetGlobalCursor();
            document.body.addClass("cursor-"+cursorType);
            window.appMap.globalCursor=cursorType;
        }
    },
    //Unset any cursot class
    unsetGlobalCursor:function(){
        if(window.appMap.globalCursor!=undefined){
            document.body.className=document.body.className.replace(/(^|\s)cursor-[a-z0-9\-\_]+/ig,'').trim();
            window.appMap.globalCursor=undefined;
        }
    },
    //Get cursor/touch position for this event
    cursor:function(e,plus){
        return {
            x:((e.touches!=undefined && e.touches.length>0)?e.touches[0].clientX:e.clientX)+(plus!=undefined?plus:0),
            y:((e.touches!=undefined && e.touches.length>0)?e.touches[0].clientY:e.clientY)+(plus!=undefined?plus:0)
        }
    },
    //Get cursor/touch position for this event, but coordinates are relative to the map, not to the window
    cursorRelative:function(e,plus,object){
        const bbox=(object==undefined?window.appMap.rootDOM:object).getBoundingClientRect();
        return {
            x:((e.touches!=undefined && e.touches.length>0)?e.touches[0].clientX:e.clientX)-bbox.x+(plus!=undefined?plus:0),
            y:((e.touches!=undefined && e.touches.length>0)?e.touches[0].clientY:e.clientY)-bbox.y+(plus!=undefined?plus:0)
        }
    },
    overlapsWithAnySibbling:function(element){
        var marginX=window.appMap.border;
        var marginY=window.appMap.border;
        var overlap=false;
        rect1=element.getBoundingClientRect();
        element.parentNode.querySelectorAll(":scope > .applicationNode").forEach(sibling=>{
            if(sibling.getAttribute('data-appid')!=element.getAttribute('data-appid')){
                rect2=sibling.getBoundingClientRect();
                if(!(rect1.right+marginX<rect2.left || rect1.left-marginX>rect2.right || rect1.bottom+marginY<rect2.top || rect1.top-marginY>rect2.bottom)){
                    overlap=true;
                    return false;
                }
            }
        });
        return overlap;
    },
    rnd:function(min,max){
      return (Math.random()*(max-min+1))+min;
    },
    randomVector2:function(magnitude){
        angleRadians=window.appMap.rnd(0,360)*Math.PI/180;
        return{x:Math.cos(angleRadians)*(magnitude),y:Math.sin(angleRadians)*(magnitude/3)};
    }
}

//Dragging apps, areas and shapes around

window.appMap.registerEvents({
    appMapMouseDown:e=>{
        //Check if it's a default mode and if mouse is point at an application and if we're not creating a new integration
        if((e.target.hasAttribute('data-appid') || e.target.hasClass('mapArea') || e.target.hasClass('mapShape')) && window.appMap.connecting==undefined){
            //Remove align options if they exist
            var alignOptions=document.querySelector("body > .alignOptions");
            if(alignOptions!=null) alignOptions.remove();
            //Select app or area based on element
            window.appMap.selectElement(e);
            //Set temporary variables
            window.appMap.dragging=true;
            window.appMap.dragRect={top:9999,left:9999,bottom:0,right:0}; //This will hold a rectangle wich contains all the selected apps
            window.appMap.draggingElements=[]; //These will store apps to udpate on mouse move
            //For areas we can have multiple resize types in addition to just moving it around
            window.appMap.dragType=e.target.hasAttribute('dragtype')?e.target.getAttribute('dragtype'):'move';
            //Set to true to update lines
            window.appMap.updateLinesOnDrag=false;
            //Go through every selected app
            window.appMap.rootDOM.querySelectorAll(":scope > .applicationNode.selected,:scope > .mapArea.selected,:scope > .mapShape.selected").forEach(element=>{
                //Add element to the list of elements being dragged
                window.appMap.draggingElements.push(element);
                //When dragging applications, update the lines
                if(element.hasClass('applicationNode')) window.appMap.updateLinesOnDrag=true;
                //Remember original positions
                var pos=window.appMap.getPosition(element);
                element.setAttribute('dragX',pos.x);
                element.setAttribute('dragY',pos.y);
                element.addClass('dragging'); //Sets z-index, other css
                if(window.appMap.dragType=='move'){
                    window.appMap.setGlobalCursor("dragging"); //Set cursor
                }
                //Get limits of the box we're dragging by comparing it against every selected item
                var box=element.getBoundingClientRect();
                window.appMap.dragRect.top=Math.min(window.appMap.dragRect.top,box.top-window.appMap.mapRect.top);
                window.appMap.dragRect.left=Math.min(window.appMap.dragRect.left,box.left-window.appMap.mapRect.left);
                window.appMap.dragRect.bottom=Math.max(window.appMap.dragRect.bottom,box.top+box.height-window.appMap.mapRect.top);
                window.appMap.dragRect.right=Math.max(window.appMap.dragRect.right,box.left+box.width-window.appMap.mapRect.left);
            });
        }
    },
    appMapMouseMove:e=>{
        if(window.appMap.dragging && window.appMap.dragType=='move'){
            //Prevent touchmove event from scrolling the page
            if(e.detail.originalEvent.type=='touchmove') e.detail.originalEvent.preventDefault();
            let translation=e.detail.translation;
            //Do not allow dragRect to be dragged outside of the map
            if(window.appMap.dragRect.top+translation.y<0){
                translation.y-=window.appMap.dragRect.top+translation.y;
            }
            if(window.appMap.dragRect.left+translation.x<0){
                translation.x-=window.appMap.dragRect.left+translation.x;
            }
            if(window.appMap.dragRect.bottom+translation.y>window.appMap.mapRect.height){
                translation.y-=window.appMap.dragRect.bottom+translation.y-window.appMap.mapRect.height;
            }
            if(window.appMap.dragRect.right+translation.x>window.appMap.mapRect.width){
                translation.x-=window.appMap.dragRect.right+translation.x-window.appMap.mapRect.width;
            }
            //Snap to grid
            if(window.appMap.snapToGrid){
                //Calculate which side on X axis is closest to the snap line
                var lSnap=(window.appMap.dragRect.left+translation.x)%window.appMap.map_style.map.gridSize;
                if(window.appMap.map_style.map.gridSize-lSnap<lSnap) lSnap=-(window.appMap.map_style.map.gridSize-lSnap);
                var rSnap=(window.appMap.dragRect.right+translation.x)%window.appMap.map_style.map.gridSize;
                if(window.appMap.map_style.map.gridSize-rSnap<rSnap) rSnap=-(window.appMap.map_style.map.gridSize-rSnap);
                //If one of the distances is smaller than snap size, snap to the shorter one
                if(Math.abs(lSnap)<=window.appMap.gridSnapSize || Math.abs(rSnap)<=window.appMap.gridSnapSize){
                     translation.x-=Math.abs(lSnap)<Math.abs(rSnap)?lSnap:rSnap;
                }
                //Calculate which side on X axis is closest to the snap line
                var tSnap=(window.appMap.dragRect.top+translation.y)%window.appMap.map_style.map.gridSize;
                if(window.appMap.map_style.map.gridSize-tSnap<tSnap) tSnap=-(window.appMap.map_style.map.gridSize-tSnap);
                var bSnap=(window.appMap.dragRect.bottom+translation.y)%window.appMap.map_style.map.gridSize;
                if(window.appMap.map_style.map.gridSize-bSnap<bSnap) bSnap=-(window.appMap.map_style.map.gridSize-bSnap);
                //If one of the distances is smaller than snap size, snap to the shorter one
                if(Math.abs(tSnap)<=window.appMap.gridSnapSize || Math.abs(bSnap)<=window.appMap.gridSnapSize){
                     translation.y-=Math.abs(tSnap)<Math.abs(bSnap)?tSnap:bSnap;
                }
            }
            //Move all selected elements
            window.appMap.draggingElements.forEach(element=>{
                var newPos={
                    x:parseFloat(element.getAttribute('dragX'))+translation.x,
                    y:parseFloat(element.getAttribute('dragY'))+translation.y
                }
                window.appMap.setPosition(element,newPos.x,newPos.y);
            });
            //Update all connecting lines
            if(window.appMap.updateLinesOnDrag) svgline.prototype.updateLinesSpread();
        }
    },
    appMapMouseUp:e=>{
        if(window.appMap.dragging){
            //Find biggest z-index among apps that are not being dragged
            var zindex=0;
            for(var i=0;i<window.appMap.appdata.length;i++){
                 if(!window.appMap.appdata[i].object.hasClass('selected')){
                     const newZindex=parseInt(window.appMap.appdata[i].object.css('z-index'));
                     if(newZindex>zindex) zindex=newZindex;
                }
            }
            //Iterate through all elements we dragged
            var positionsToSave=[];
            var saveStyle=false;
            window.appMap.draggingElements.forEach(element=>{
                //Remove temporary attributes and 'dragging' class
                element.removeAttribute('dragX');
                element.removeAttribute('dragY');
                element.removeClass('dragging');
                var pos=window.appMap.getPosition(element);
                if(element.getAttribute('data-appid')){
                    //Set z-index to be higher than other elements
                    element.style.zIndex=zindex+1;
                    //Add position of the element for saving
                    positionsToSave.push({
                        id:element.getAttribute('data-appid'),
                        left:pos.x,
                        top:pos.y
                    });
                }else if(element.hasClass('mapArea')){
                    saveStyle=true;
                    //Apply style to the area
                    window.appMap.style('area',element.getAttribute('data-areaid'),{
                        x:pos.x,
                        y:pos.y,
                        width:parseFloat(element.style.width),
                        height:parseFloat(element.style.height)
                    });
                }else if(element.hasClass('mapShape')){
                    saveStyle=true;
                    //Apply style to the area
                    window.appMap.style('shape',element.getAttribute('data-shapeid'),{
                        x:pos.x,
                        y:pos.y,
                        width:parseFloat(element.style.width),
                        height:parseFloat(element.style.height)
                    });
                    //If dropped single shape, we check if it's dropped onto an integration line or
                    //if it was already attached to a line check if we need to detach it, attach to other line or sort it
                    if(window.appMap.draggingElements.length==1 && window.appMap.dragType=='move'){
                        window.appMap.dropSingleShape(e,element);
                    }
                }
            });
            window.appMap.unsetGlobalCursor();
            //Unset all used variables
            window.appMap.dragging=undefined;
            window.appMap.dragRect=undefined;
            window.appMap.draggingElements=undefined;
            window.appMap.dragType=undefined;
            //If we need to save the new positions, we're saving them
            if(e.detail.dragDistance>0 && positionsToSave.length>0) window.appMap.savePositions(positionsToSave);
            //If areas were involved we need to save map style
            if(saveStyle) window.appMap.saveMapStyle();
            //Recreate the toolbar. This is basically only needed to show align toolbar
            window.appMap.createMainToolbar();
        }
    }
});

window.appMap.detachShape=function(shapeid){
    let shapeStyle=window.appMap.map_style.shapes.find(function(item){return item.id==shapeid;});
    let line=window.svglines.find(l=>l.integration.id==shapeStyle.integrationID);
    let removeIndex=line.integration.lineAttachments.findIndex(a=>parseInt(a.getAttribute('data-shapeid'))==shapeid);
    line.integration.lineAttachments.splice(removeIndex,1);
}

window.appMap.dropSingleShape=function(e,element){
    let shapeid=element.getAttribute('data-shapeid');
    let shapeStyle=window.appMap.map_style.shapes.find(function(item){return item.id==shapeid;});
    let shapeIntID=parseInt(e.detail.originalTargetStyle.integrationID);
    //If dropped onto a line
    if(window.appMap.hoverLine!=undefined){
        let line=window.appMap.hoverLine;
        let intid=line.svg.getAttribute("data-intid");
        //Detach from any line it's attached to
        if(shapeIntID>0){
            window.appMap.detachShape(shapeid);
        }
        //If there are other attachments on this line, we need to do the sorting
        if(line.integration.lineAttachments!=undefined && line.integration.lineAttachments.length>0){
            let place,position,distance;
            //Calculate to which point on the line this attachment is closer at the moment
            //We add 1 to number of attachments so we have points on left and right of every existing attachments
            for(let i=0;i<line.integration.lineAttachments.length+1;i++){
                let pos=(1/(line.integration.lineAttachments.length+2))*(i+1);
                let point=line.getPoint(pos);
                let pDistance=Math.sqrt(Math.pow(point.x-e.detail.cursor.x,2)+Math.pow(point.y-e.detail.cursor.y,2));
                if(distance==undefined || distance>pDistance){
                    place=i;
                    position=pos;
                    distance=pDistance;
                }
            }
            //Insert the element at a proper place
            if(place<line.integration.lineAttachments.length){
                line.integration.lineAttachments.splice(place,0,element);
            }else{
                line.integration.lineAttachments.push(element);
            }
            //Find zero element (description). It always has order 0 because we don't (can't) order it
            let zeroIndex=0;
            line.integration.lineAttachments.forEach((attachment,i)=>{
                if(attachment.hasClass("connectionDescription")){
                    zeroIndex=i;
                }
            });
            //Now set the order for attachments based on zero index
            line.integration.lineAttachments.forEach((attachment,i)=>{
                attachment.setAttribute('data-lineOrder',i-zeroIndex);
                //Save lineOrder for all attached shapes
                if(attachment.hasClass('mapShape')){
                    let sid=parseInt(attachment.getAttribute('data-shapeid'));
                    let ss=window.appMap.map_style.shapes.find(function(item){return item.id==sid;});
                    ss.lineOrder=i-zeroIndex;
                }
            });
            window.appMap.saveMapStyle();
        //If this line has no attachments we jsut set it to array of one
        }else{
            line.integration.lineAttachments=[element];
        }
        //If shape was not attached to any line or was attached to some different line
        if(shapeIntID==0 || shapeIntID!=intid){
            shapeStyle.integrationID=intid;
            window.appMap.style('shape',shapeid,shapeStyle);
            window.appMap.saveMapStyle();
        }
        line.forceUpdate=true; //Make sure the line updates the position of the attachment
        window.dispatchEvent(new Event('svglinesUpdate'));
    //If previously attached to a line and was dropped outside of it
    }else if(shapeIntID>0 && e.detail.dragDistance>25){
        window.appMap.detachShape(shapeid);
        shapeStyle.integrationID=0;
        window.appMap.style('shape',shapeid,shapeStyle);
        window.appMap.saveMapStyle();
        window.dispatchEvent(new Event('svglinesUpdate'));
    }
}

//Resize area or shape

window.appMap.registerEvents({
    appMapMouseMove:e=>{
        if(window.appMap.dragging && window.appMap.dragType!='move'){
            let is_shape=e.detail.originalTargetType=='shape';
            let element=e.detail.originalTarget;
            let type=window.appMap.dragType;
            let translation=e.detail.translation; //Difference between mouse position on mousedown and now
            let centered=e.altKey || (is_shape && parseInt(e.detail.originalTargetStyle.integrationID)>0);
            let proportional=((e.shiftKey || is_shape) && ['topleft','topright','bottomright','bottomleft'].includes(type));
            let proportion=undefined;
            //For proportional scaling of the shape we get the intended proportion from an SVG
            if(proportional && is_shape){
                let svg=element.querySelector("svg");
                proportion=parseFloat(svg.getAttribute('width'))/parseFloat(svg.getAttribute('height'));
            }
            //The single element we'll be resizing, since we'll not resize multiple selected elements
            let resizeBox={ //Create a box of this element as it was before resizing
                left:e.detail.originalTargetRect.left-window.appMap.mapRect.left,
                top:e.detail.originalTargetRect.top-window.appMap.mapRect.top,
                right:e.detail.originalTargetRect.left+e.detail.originalTargetRect.width-window.appMap.mapRect.left,
                bottom:e.detail.originalTargetRect.top+e.detail.originalTargetRect.height-window.appMap.mapRect.top,
                width:e.detail.originalTargetRect.width,
                height:e.detail.originalTargetRect.height,
                centerX:e.detail.originalTargetRect.left-window.appMap.mapRect.left+e.detail.originalTargetRect.width/2,
                centerY:e.detail.originalTargetRect.top-window.appMap.mapRect.top+e.detail.originalTargetRect.height/2
            };
            //Snap to grid
            if(window.appMap.snapToGrid){
                let lSnap,rSnap,tSnap,bSnap=window.appMap.map_style.map.gridSize+1;
                //Calculate which side on X axis is closest to the snap line
                if(type=='left' || type=='topleft' || type=='bottomleft'){
                    lSnap=(resizeBox.left+translation.x)%window.appMap.map_style.map.gridSize;
                    if(window.appMap.map_style.map.gridSize-lSnap<lSnap) lSnap=-(window.appMap.map_style.map.gridSize-lSnap);
                }
                if(type=='right' || type=='topright' || type=='bottomright'){
                    rSnap=(resizeBox.right+translation.x)%window.appMap.map_style.map.gridSize;
                    if(window.appMap.map_style.map.gridSize-rSnap<rSnap) rSnap=-(window.appMap.map_style.map.gridSize-rSnap);
                }
                //If one of the distances is smaller than snap size, snap to the shorter one
                if(Math.abs(lSnap)<=window.appMap.gridSnapSize || Math.abs(rSnap)<=window.appMap.gridSnapSize){
                    if(type=='left' || type=='topleft' || type=='bottomleft'){
                        translation.x-=lSnap;
                    }else if(type=='right' || type=='topright' || type=='bottomright'){
                        translation.x-=rSnap;
                    }
                }
                //Calculate which side on X axis is closest to the snap line
                if(type=='top' || type=='topleft' || type=='topright'){
                    tSnap=(resizeBox.top+translation.y)%window.appMap.map_style.map.gridSize;
                    if(window.appMap.map_style.map.gridSize-tSnap<tSnap) tSnap=-(window.appMap.map_style.map.gridSize-tSnap);
                }else if(type=='bottom' || type=='bottomleft' || type=='bottomright'){
                    bSnap=(resizeBox.bottom+translation.y)%window.appMap.map_style.map.gridSize;
                    if(window.appMap.map_style.map.gridSize-bSnap<bSnap) bSnap=-(window.appMap.map_style.map.gridSize-bSnap);
                }
                //If one of the distances is smaller than snap size, snap to the shorter one
                if(Math.abs(tSnap)<=window.appMap.gridSnapSize || Math.abs(bSnap)<=window.appMap.gridSnapSize){
                    if(type=='top' || type=='topleft' || type=='topright'){
                        translation.y-=tSnap;
                    }else if(type=='bottom' || type=='bottomleft' || type=='bottomright'){
                        translation.y-=bSnap;
                    }
                }
            }
            //This is the point of the rectangle that doesn't move when the rectangle is resized
            let staticPoint={x:resizeBox.centerX,y:resizeBox.centerY}; //Center of the rectangle
            if(!centered){
                if(type=='topleft'){
                    staticPoint={x:resizeBox.right,y:resizeBox.bottom};
                }else if(type=='topright'){
                    staticPoint={x:resizeBox.left,y:resizeBox.bottom};
                }else if(type=='bottomright'){
                    staticPoint={x:resizeBox.left,y:resizeBox.top};
                }else if(type=='bottomleft'){
                    staticPoint={x:resizeBox.right,y:resizeBox.top};
                }else if(type=='left'){
                    staticPoint={x:resizeBox.right,y:resizeBox.centerY};
                }else if(type=='right'){
                    staticPoint={x:resizeBox.left,y:resizeBox.centerY};
                }else if(type=='top'){
                    staticPoint={x:resizeBox.centerX,y:resizeBox.bottom};
                }else if(type=='bottom'){
                    staticPoint={x:resizeBox.centerX,y:resizeBox.top};
                }
            }
            //The point we're dragging
            let movingPoint;
            if(type=='topleft'){
                movingPoint={x:resizeBox.left,y:resizeBox.top};
            }else if(type=='topright'){
                movingPoint={x:resizeBox.right,y:resizeBox.top};
            }else if(type=='bottomright'){
                movingPoint={x:resizeBox.right,y:resizeBox.bottom};
            }else if(type=='bottomleft'){
                movingPoint={x:resizeBox.left,y:resizeBox.bottom};
            }else if(type=='left'){
                movingPoint={x:resizeBox.left,y:resizeBox.centerY};
            }else if(type=='right'){
                movingPoint={x:resizeBox.right,y:resizeBox.centerY};
            }else if(type=='top'){
                movingPoint={x:resizeBox.centerX,y:resizeBox.top};
            }else if(type=='bottom'){
                movingPoint={x:resizeBox.centerX,y:resizeBox.bottom};
            }
            //A vector from static point to original position of a moving point
            let resizeVectorOriginal={
                x:movingPoint.x-staticPoint.x,
                y:movingPoint.y-staticPoint.y
            }
            //Adding mouse translation to that vector
            let resizeVector={
                x:resizeVectorOriginal.x+translation.x,
                y:resizeVectorOriginal.y+translation.y
            }
            //If resizing is proportional
            if(proportional){
                //If proportions is not already defined, we calculate it from resizeVectorOriginal
                if(proportion==undefined) proportion=resizeBox.width/resizeBox.height;
                //Make resizeVector proportional. Resize based on a dimension which was descaled more
                if(Math.abs(resizeVector.x)>Math.abs(resizeVector.y)*proportion)
                    resizeVector.x=(Math.abs(resizeVector.y)*proportion)*Math.sign(resizeVector.x);
                else
                    resizeVector.y=(Math.abs(resizeVector.x)/proportion)*Math.sign(resizeVector.y);
            }
            //Check if resizeVector is out of the map and limit it
            //TODO: while dragging opposite corner in symetric resizing mode, object can be limited by a larger dimension instead of smaller
            let ms=[1]; //Multipliers
            if(centered) ms.push(-1); //If resizing is symetrical we need to also check the mirrored point
            ms.forEach(m=>{
                let movingPointPosition={
                    x:staticPoint.x+resizeVector.x*m,
                    y:staticPoint.y+resizeVector.y*m
                }
                if(movingPointPosition.x<0 && movingPointPosition.x<movingPointPosition.y){
                    resizeVector.x-=movingPointPosition.x*m;
                    if(proportional) resizeVector.y=resizeVector.x/proportion;
                }
                if(movingPointPosition.y<0 && movingPointPosition.y<movingPointPosition.x){
                    resizeVector.y-=movingPointPosition.y*m;
                    if(proportional) resizeVector.x=resizeVector.y*proportion;
                }
                if(movingPointPosition.x>window.appMap.mapRect.width && movingPointPosition.x-window.appMap.mapRect.width>movingPointPosition.y-window.appMap.mapRect.height){
                    resizeVector.x-=(movingPointPosition.x-window.appMap.mapRect.width)*m;
                    if(proportional) resizeVector.y=resizeVector.x/proportion;
                }
                if(movingPointPosition.y>window.appMap.mapRect.height && movingPointPosition.y-window.appMap.mapRect.height>movingPointPosition.x-window.appMap.mapRect.width){
                    resizeVector.y-=(movingPointPosition.y-window.appMap.mapRect.height)*m;
                    if(proportional) resizeVector.x=resizeVector.y*proportion;
                }
            });
            //If it's a centered resizing, we mirror resizeVector against the center (staticPoint) and then double the resizeVector
            if(centered){
                staticPoint={x:staticPoint.x-resizeVector.x,y:staticPoint.y-resizeVector.y} //Keep in mind that this means that static point is now not static
                resizeVector={x:resizeVector.x*2,y:resizeVector.y*2};
            }
            //Create a rectangle based on resize type, static point and resize vector
            let newRect;
            if(type=='topleft'){
                newRect={
                    left:staticPoint.x+resizeVector.x,
                    top:staticPoint.y+resizeVector.y,
                    width:-resizeVector.x,
                    height:-resizeVector.y
                }
            }else if(type=='topright'){
                newRect={
                    left:staticPoint.x,
                    top:staticPoint.y+resizeVector.y,
                    width:resizeVector.x,
                    height:-resizeVector.y
                }
            }else if(type=='bottomright'){
                newRect={
                    left:staticPoint.x,
                    top:staticPoint.y,
                    width:resizeVector.x,
                    height:resizeVector.y
                }
            }else if(type=='bottomleft'){
                newRect={
                    left:staticPoint.x+resizeVector.x,
                    top:staticPoint.y,
                    width:-resizeVector.x,
                    height:resizeVector.y
                }
            }else if(type=='left'){
                newRect={
                    left:staticPoint.x+resizeVector.x,
                    top:resizeBox.top,
                    width:-resizeVector.x,
                    height:resizeBox.height
                }
            }else if(type=='right'){
                newRect={
                    left:staticPoint.x,
                    top:resizeBox.top,
                    width:resizeVector.x,
                    height:resizeBox.height
                }
            }else if(type=='top'){
                newRect={
                    left:resizeBox.left,
                    top:staticPoint.y+resizeVector.y,
                    width:resizeBox.width,
                    height:-resizeVector.y
                }
            }else if(type=='bottom'){
                newRect={
                    left:resizeBox.left,
                    top:staticPoint.y,
                    width:resizeBox.width,
                    height:resizeVector.y
                }
            }
            //On negative width or height invert the left/top
            newRect={
                left:Math.min(newRect.left,newRect.left+newRect.width),
                top:Math.min(newRect.top,newRect.top+newRect.height),
                width:Math.abs(newRect.width),
                height:Math.abs(newRect.height)
            }
            //Set new position and size
            window.appMap.setPosition(element,newRect.left,newRect.top);
            element.style.width=newRect.width+"px";
            element.style.height=newRect.height+"px";
        }
    }
});

//Selecting elements using a selection box in default cursor mode
window.appMap.registerEvents({
    appMapMouseDown:e=>{
        //Check if in default cursor mode and click target is the map itself
        if(e.detail.mode=='default' && e.target==window.appMap.rootDOM){
            window.appMap.selecting=true;
            window.appMap.selectRect=document.createElement("div");
            window.appMap.selectRect.addClass('selectionRect');
            window.appMap.rootDOM.appendChild(window.appMap.selectRect);
            window.appMap.setPosition(
                window.appMap.selectRect,
                e.detail.cursorOrigin.x+1,
                e.detail.cursorOrigin.y+1
            );
        }
    },
    appMapMouseMove:e=>{
        if(e.detail.mode=='default' && window.appMap.selecting){
            let cursor=e.detail.cursor;
            //Limit cursor by map
            cursor.x=Math.max(Math.min(cursor.x,window.appMap.mapRect.width),0);
            cursor.y=Math.max(Math.min(cursor.y,window.appMap.mapRect.height),0);
            //Calculate selection box
            var selBox={
                x:Math.min(e.detail.cursorOrigin.x,cursor.x),
                y:Math.min(e.detail.cursorOrigin.y,cursor.y),
                width:Math.max(e.detail.cursorOrigin.x,cursor.x)-Math.min(e.detail.cursorOrigin.x,cursor.x),
                height:Math.max(e.detail.cursorOrigin.y,cursor.y)-Math.min(e.detail.cursorOrigin.y,cursor.y)
            }
            //Position selection box
            window.appMap.setPosition(window.appMap.selectRect,selBox.x,selBox.y);
            window.appMap.selectRect.style.width=selBox.width+"px";
            window.appMap.selectRect.style.height=selBox.height+"px";
            //Check which elements are positioned within the selection rect
            window.appMap.rootDOM.querySelectorAll(":scope > .applicationNode,:scope > .mapArrow,:scope > .mapArea,:scope > .mapShape").forEach(element=>{
                if(element.tagName=='svg'){
                    var appBox=element.getElementsByClassName("path")[0].getBoundingClientRect();
                }else{
                    var appBox=element.getBoundingClientRect();
                }
                appBox={
                    x:appBox.left-window.appMap.mapRect.left,
                    y:appBox.top-window.appMap.mapRect.top,
                    width:appBox.width,
                    height:appBox.height
                }
                //If app is inside the selection box
                if(appBox.x>=selBox.x && Math.round(appBox.x+appBox.width)<=selBox.x+selBox.width && appBox.y>=selBox.y && Math.round(appBox.y+appBox.height)<=selBox.y+selBox.height){
                    if(!element.hasClass('selected')){
                        element.addClass('selected');
                        window.appMap.createMainToolbar();
                    }
                //If app is not inside the selection box
                }else{
                    if(element.hasClass('selected')){
                        element.removeClass('selected');
                        window.appMap.createMainToolbar();
                    }
                }
            });
        }
    },
    appMapMouseUp:e=>{
        if(e.detail.mode=='default' && window.appMap.selecting){
            if(e.detail.dragDistance<10) window.appMap.deselectEverything();
            window.appMap.selecting=false;
            window.appMap.selectRect.remove();
            window.appMap.selectRect=undefined;
            //Make sure that align toolbar appears
            window.appMap.createMainToolbar();
        }
    }
});

//Auto-scroll when selecting, dragging or creating a connection

window.appMap.registerEvents({
    appMapMouseMove:e=>{
        if(e.detail.originalEvent.type!="scroll"){
            let scrollable=document.querySelector(".appContainerScrollable");
            if(scrollable){
                //If selecting or dragging and the object has a scrollable parrent and autoscroll didn't start yet
                let scrollable=document.querySelector(".appContainerScrollable");
                if((window.appMap.selecting || window.appMap.dragging || window.appMap.connecting) && scrollable && !window.appMap.autoScroll){
                    window.appMap.autoScroll=true;
                    //Start autoscrolling animation, request new frames while selecting or scrolling
                    window.requestAnimationFrame(function step(timestamp){
                        //If the scroll speed is set, do the scrolling and keep it withing acceptable range
                        if(window.appMap.scrollSpeed!=undefined){
                            if(window.appMap.scrollSpeed.x!=0) scrollable.scrollLeft=Math.max(0,Math.min(scrollable.scrollWidth-scrollable.clientWidth,scrollable.scrollLeft+window.appMap.scrollSpeed.x));
                            if(window.appMap.scrollSpeed.y!=0) scrollable.scrollTop=Math.max(0,Math.min(scrollable.scrollHeight-scrollable.clientHeight,scrollable.scrollTop+window.appMap.scrollSpeed.y));
                        }
                        if(window.appMap.selecting || window.appMap.dragging || window.appMap.connecting){
                            window.requestAnimationFrame(step);
                        }
                    });
                }
                //This part regulates the speed of scrolling
                const hasScrollableContent=scrollable.scrollHeight>scrollable.clientHeight || scrollable.scrollWidth>scrollable.clientWidth;
                if(hasScrollableContent){
                    let relPos=window.appMap.cursorRelative(e.detail.originalEvent,0,scrollable);
                    let scrollRect=scrollable.getBoundingClientRect();
                    let edgeDist=50;
                    let maxScollSpeed=5;
                    //Set the speed of scrolling to be applied every frame
                    window.appMap.scrollSpeed={
                        x:(relPos.x<edgeDist?Math.max(-1,-(1-(relPos.x/edgeDist))):(scrollRect.width-relPos.x<edgeDist?Math.min(1,1-((scrollRect.width-relPos.x)/edgeDist)):0)),
                        y:(relPos.y<edgeDist?Math.max(-1,-(1-(relPos.y/edgeDist))):(scrollRect.height-relPos.y<edgeDist?Math.min(1,1-((scrollRect.height-relPos.y)/edgeDist)):0))
                    };
                    //Multiply by maximum scrolling speed and round to integer because scrolling can' be set in floats
                    window.appMap.scrollSpeed={
                        x:Math.round(maxScollSpeed*window.appMap.scrollSpeed.x),
                        y:Math.round(maxScollSpeed*window.appMap.scrollSpeed.y)
                    }
                }
            }
        }
    },
    appMapMouseUp:e=>{
        window.appMap.autoScroll=false;
    }
});

//Deal with creating new connections

window.appMap.cursorFollowSetPos=(e)=>{
    if(e.detail.originalEvent.type!="scroll"){
        let cursor=window.appMap.cursor(e.detail.originalEvent,-2); //We need to get global cursor position based on original event
        let mapRect=window.appMap.rootDOM.getBoundingClientRect();
        //Limit cursor with the map
        cursor.x=Math.min(mapRect.x+mapRect.width,Math.max(mapRect.x,cursor.x));
        cursor.y=Math.min(mapRect.y+mapRect.height,Math.max(mapRect.y,cursor.y));
        //Set positions
        window.appMap.cursorFollow.style.left=cursor.x+"px";
        window.appMap.cursorFollow.style.top=cursor.y+"px";
    }
    //Update the lines
    svgline.prototype.updateLinesSpread();
}

window.appMap.connectionStop=()=>{
    let hoverApps=document.querySelectorAll(".applicationNode.hover");
    if(hoverApps) hoverApps.forEach(el=>el.removeClass('hover'));
    window.appMap.connecting.line.remove();
    window.appMap.connecting=undefined;
}

window.appMap.registerEvents({
    appMapMouseDown:e=>{
        //Start creating a new connection
        if(e.target.hasClass('addConnection') || e.target.parentNode.hasClass('addConnection')){
            let appNode=e.target.closest(".applicationNode");
            let id=parseInt(appNode.getAttribute('data-appid'));
            if(window.appMap.cursorFollow==undefined) window.appMap.cursorFollow=document.querySelector('.cursorFollow');
            window.appMap.connecting={
                'fromID':id,
                'time':new Date(),
                'line':new svgline(appNode,window.appMap.cursorFollow,{
                    endArrow:true,
                    dash:true,
                    parent:window.appMap.rootDOM
                })
            };
            appNode.querySelector(".applicationMenu").remove();
            //Follow mouse with invisible div
            window.appMap.cursorFollowSetPos(e);
        }
        //Cancelling the connection when clicking anywhere except the app
        if(window.appMap.connecting && (new Date()-window.appMap.connecting.time)>300 && !event.target.matches(".applicationNode.hover")){
            window.appMap.connectionStop();
        }
        //Finish craeting a new connection
        if(e.target.hasAttribute('data-appid') && e.target.hasClass('hover') && window.appMap.connecting!=undefined && !window.appMap.connecting.lock){
            let fromID=window.appMap.connecting.fromID;
            let toID=e.target.getAttribute('data-appid');
            let appdata=window.appMap.appdata;
            let integrations=window.appMap.integrations;
            window.appMap.connecting.line.svg.style.stroke="#777";
            window.appMap.connecting.line.svg.style.fill="#777";
            window.appMap.connecting.lock=true;
            $.ajax({
                type:'post',
                url:'/organization/'+window.appMap.organization_id+'/application/'+fromID+'/integrations/save',
                data:{
                    appIDtarget:toID,
                    directionID:0,
                    calledFromGraph:true
                },
                headers:{'X-CSRF-Token':$('meta[name="csrf-token"]').attr('content')},
                datatype:'json',
                success:function(data,status,xhr){
                    window.appMap.connectionStop();
                    var ikey1=integrations.findIndex(function(item){return item.id==data.id});
                    var ikey2=integrations.findIndex(function(item){return item.id==data.idCopy});
                    if(ikey1>-1){
                        integrations[ikey1]=jQuery.parseJSON(data.integration);
                    }else if(ikey2>-1){
                        integrations[ikey2]=jQuery.parseJSON(data.integrationCopy);
                    }else{
                        integrations.push(jQuery.parseJSON(data.integration));
                    }
                    window.appMap.updateIntegrations(integrations);
                    window.appMap.redrawLines();
                },
                error:function(xhr,status,error){
                    window.appMap.connectionStop();
                }
            });
        }
    },
    appMapMouseMove:e=>{
        //Follow mouse with ibvisible div when creating a connection
        if(window.appMap.connecting!=undefined && !window.appMap.connecting.lock){
            window.appMap.cursorFollowSetPos(e);
        }
    }
});

//Selecting a connection by clicking on an arrow or its description

window.appMap.registerEvents({
    appMapMouseDown:e=>{
        if((e.target.hasClass('mapArrow') || e.target.hasClass('pathHover') || e.target.hasClass('connectionDescription')) && window.appMap.connecting==undefined){
            window.appMap.selectElement(e);
        }
    }
});

//Clicking on FAQ element or on a shape with description, showing a popover

window.appMap.registerEvents({
    appMapMouseDown:e=>{
        //Hiding existing popover on clicking anywhere except the popover itself or the original element
        // if(window.appMap.popoverShown!=undefined){
        //     $(window.appMap.popoverShown).hide();
        //     window.appMap.popoverShown=undefined;
        // }
        //Show popover when clicking on a FAQ icon
        if(e.target.hasClass('connectionFaq') && window.appMap.connecting==undefined){

            let faqid=e.target.getAttribute('data-faqid');
            let intid=e.target.getAttribute('data-intid');
            let integration=window.appMap.integrations.find(function(item){return item.id==intid});
            let content="<ul class='ps-2'>";
            integration.faqs.forEach(faq=>{
                let faqurl=getRoute('organization.faqs.show',[window.appMap.organization_id,faq.id]);
                content+="<li><a href='"+faqurl+"' target='_blank'>"+faq.title+"</a></li>";
            });
            content+="</ul>";

            let popover = new bootstrap.Popover(e.target, {
                'title': "Articles",
                'content':content,
                'trigger': 'click focus',
                'placement':'top',
                'html':true,
                'template': '<div class="popover shadow-lg"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
            });

            e.target.addEventListener('hidden.bs.popover', function () {
                popover.hide();
            });

            // window.appMap.popoverShown=popover;
        }
        //Show popover when clicking a shape with a description
        if(e.target.hasClass('shapePopover') && window.appMap.connecting==undefined){

            let shapeElement=e.target.closest(".mapShape");
            const shapeid=shapeElement.getAttribute('data-shapeid');
            const shape=window.appMap.map_style.shapes.find(function(item){return item.id==shapeid;});
            if(shape.description!=undefined && typeof shape.description==='string' && shape.description.length>0){

            let popover = new bootstrap.Popover(e.target, {
                'title':shape.title,
                'content':shape.description,
                'trigger': 'click focus',
                'placement':'top',
                'html':true,
                'template': '<div class="popover shadow-lg"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
            });

            e.target.addEventListener('hidden.bs.popover', function () {
                popover.hide();
            });

            // window.appMap.popoverShown=popover;
            }
        }
    }
});

//Create areas when map is in area mode

window.appMap.registerEvents({
    appMapMouseDown:e=>{
        if(e.detail.mode=='area' && e.target==window.appMap.rootDOM){
            window.appMap.creatingArea=true;
            window.appMap.mapArea=window.appMap.createAreaElement(" "," ");
            window.appMap.mapArea.addClass('editing');
            window.appMap.rootDOM.appendChild(window.appMap.mapArea);
            window.appMap.setPosition(
                window.appMap.mapArea,
                e.detail.cursorOrigin.x+1,
                e.detail.cursorOrigin.y+1
            );
            window.appMap.mapArea.style.width="0px";
            window.appMap.mapArea.style.height="0px";
            window.appMap.deselectEverything();
        }
    },
    appMapMouseMove:e=>{
        if(window.appMap.creatingArea){
            let cursor=e.detail.cursor;
            //Limit cursor by map
            cursor.x=Math.max(Math.min(cursor.x,window.appMap.mapRect.width),0);
            cursor.y=Math.max(Math.min(cursor.y,window.appMap.mapRect.height),0);
            //Calculate box
            var box={
                x:Math.min(e.detail.cursorOrigin.x,cursor.x),
                y:Math.min(e.detail.cursorOrigin.y,cursor.y),
                width:Math.max(e.detail.cursorOrigin.x,cursor.x)-Math.min(e.detail.cursorOrigin.x,cursor.x),
                height:Math.max(e.detail.cursorOrigin.y,cursor.y)-Math.min(e.detail.cursorOrigin.y,cursor.y)
            }
            //Position the area
            window.appMap.setPosition(window.appMap.mapArea,box.x,box.y);
            window.appMap.mapArea.style.width=box.width+"px";
            window.appMap.mapArea.style.height=box.height+"px";
        }
    },
    appMapMouseUp:e=>{
        if(window.appMap.creatingArea){
            if(e.detail.dragDistance<50){
                window.appMap.mapArea.remove();
                window.appMap.deselectEverything();
            }else{
                window.appMap.mapArea.removeClass('editing');
                window.appMap.mapArea.querySelector('.title').innerHTML="<span>New area</span>";
                var id=new Date().valueOf();
                window.appMap.mapArea.setAttribute('data-areaid',id);
                var pos=window.appMap.getPosition(window.appMap.mapArea);
                window.appMap.style('area',id,{
                    'title':window.appMap.mapArea.querySelector('.title span').innerHTML.trim(),
                    x:pos.x,
                    y:pos.y,
                    width:parseFloat(window.appMap.mapArea.style.width),
                    height:parseFloat(window.appMap.mapArea.style.height),
                    fill:'on',
                    position:'topcenterabove'
                });
                window.appMap.applyStyleClasses('area',id);
                window.appMap.saveMapStyle();
                //Exit area creation mode
                window.appMap.setMode(window.appMap.modes[0]);
                //Select the area and refresh toolbar
                window.appMap.mapArea.addClass('selected');
                window.appMap.createMainToolbar();
            }
            //Unset everything
            window.appMap.creatingArea=false;
            window.appMap.mapArea=undefined;
        }
    }
});

//Change cursor over the area/shape to resizing arrows

window.appMap.registerEvents({
    appMapMouseMove:e=>{
        if(e.target instanceof HTMLDocument) return false;
        if(window.appMap.dragging) return false; //Don't modify cursor while dragging. It should stay the same
        if(e.target.closest('.mapArea') || e.target.closest('.mapShape')){
            let cursor=e.detail.cursor;
            let borders=10;
            if(e.target.closest('.mapArea')){
                var rect=e.target.closest('.mapArea').getBoundingClientRect();
            }else if(e.target.closest('.mapShape')){
                var rect=e.target.closest('.mapShape').getBoundingClientRect();
            }
            //Turn into relative position
            rect.x-=window.appMap.mapRect.x;
            rect.y-=window.appMap.mapRect.y;
            //Top left corner
            if(cursor.x>=rect.x && cursor.x<rect.x+borders && cursor.y>=rect.y && cursor.y<rect.y+borders){
                window.appMap.setGlobalCursor("resize-top-left");
                e.target.setAttribute('dragtype','topleft');
            //Top right corner
            }else if(cursor.x>=rect.x+rect.width-borders && cursor.x<rect.x+rect.width && cursor.y>=rect.y && cursor.y<rect.y+borders){
                window.appMap.setGlobalCursor("resize-top-right");
                e.target.setAttribute('dragtype','topright');
            //Bottom right corner
            }else if(cursor.x>=rect.x+rect.width-borders && cursor.x<rect.x+rect.width && cursor.y>=rect.y+rect.height-borders && cursor.y<rect.y+rect.height){
                window.appMap.setGlobalCursor("resize-bottom-right");
                e.target.setAttribute('dragtype','bottomright');
            //Bottom left corner
            }else if(cursor.x>=rect.x && cursor.x<rect.x+borders && cursor.y>=rect.y+rect.height-borders && cursor.y<rect.y+rect.height){
                window.appMap.setGlobalCursor("resize-bottom-left");
                e.target.setAttribute('dragtype','bottomleft');
            //Left side
            }else if(cursor.x>=rect.x && cursor.x<rect.x+borders){
                window.appMap.setGlobalCursor("resize-left");
                e.target.setAttribute('dragtype','left');
            //Right side
            }else if(cursor.x>=rect.x+rect.width-borders && cursor.x<rect.x+rect.width){
                window.appMap.setGlobalCursor("resize-right");
                e.target.setAttribute('dragtype','right');
            //Top side
            }else if(cursor.y>=rect.y && cursor.y<rect.y+borders){
                window.appMap.setGlobalCursor("resize-top");
                e.target.setAttribute('dragtype','top');
            //Bottom side
            }else if(cursor.y>=rect.y+rect.height-borders && cursor.y<rect.y+rect.height){
                window.appMap.setGlobalCursor("resize-bottom");
                e.target.setAttribute('dragtype','bottom');
            }else{
                window.appMap.unsetGlobalCursor();
                e.target.setAttribute('dragtype','move');
            }
        }else{
            window.appMap.unsetGlobalCursor();
        }
    }
});

//Double clicking on app, integration or area to open the settings

window.appMap.registerEvents({
    appMapDoubleClick:e=>{
        if(e.target.hasClass('applicationNode')){
            window.appMap.appOpenEditModal(e);
        }
        if((e.target.hasClass('mapArrow') || e.target.hasClass('pathHover') || e.target.hasClass('connectionDescription'))){
            window.appMap.connectionSettingsModal(e);
        }
        if(e.target.hasClass('mapArea')){
            window.appMap.areaSettingsModal(e);
        }
        if(e.target.hasClass('mapShape')){
            window.appMap.shapeSettingsModal(e);
        }
        //Debug redrawing lines
        // if(e.target.hasClass('appContainer')){
        //     window.appMap.redrawLines();
        // }
    }
});

//Drawing shapes on the map

window.appMap.registerEvents({
    appMapMouseDown:e=>{
        if(e.detail.mode=='shape' && e.target==window.appMap.rootDOM){
            window.appMap.creatingShape=true;
            window.appMap.mapShape=window.appMap.createShapeElement(window.appMap.shapeSVGs[0].type);
            window.appMap.mapShape.addClass('editing');
            window.appMap.rootDOM.appendChild(window.appMap.mapShape);
            window.appMap.setPosition(
                window.appMap.mapShape,
                e.detail.cursorOrigin.x+1,
                e.detail.cursorOrigin.y+1
            );
            window.appMap.mapShape.style.width="0px";
            window.appMap.mapShape.style.height="0px";
            window.appMap.deselectEverything();
        }
    },
    appMapMouseMove:e=>{
        if(window.appMap.creatingShape){
            let cursor=e.detail.cursor;
            //Limit cursor by map
            cursor.x=Math.max(Math.min(cursor.x,window.appMap.mapRect.width),0);
            cursor.y=Math.max(Math.min(cursor.y,window.appMap.mapRect.height),0);
            //Calculate box
            var box={
                x:Math.min(e.detail.cursorOrigin.x,cursor.x),
                y:Math.min(e.detail.cursorOrigin.y,cursor.y),
                width:Math.max(e.detail.cursorOrigin.x,cursor.x)-Math.min(e.detail.cursorOrigin.x,cursor.x),
                height:Math.max(e.detail.cursorOrigin.y,cursor.y)-Math.min(e.detail.cursorOrigin.y,cursor.y)
            }
            //Position the area
            window.appMap.setPosition(window.appMap.mapShape,box.x,box.y);
            window.appMap.mapShape.style.width=box.width+"px";
            window.appMap.mapShape.style.height=box.height+"px";
        }
    },
    appMapMouseUp:e=>{
        if(window.appMap.creatingShape){
            if(e.detail.dragDistance<50){
                window.appMap.mapShape.remove();
                window.appMap.deselectEverything();
            }else{
                window.appMap.mapShape.removeClass('editing');
                var id=new Date().valueOf();
                window.appMap.mapShape.setAttribute('data-shapeid',id);
                var pos=window.appMap.getPosition(window.appMap.mapShape);
                window.appMap.style('shape',id,{
                    type:window.appMap.shapeSVGs[0].type,
                    x:pos.x,
                    y:pos.y,
                    width:parseFloat(window.appMap.mapShape.style.width),
                    height:parseFloat(window.appMap.mapShape.style.height)
                });
                window.appMap.saveMapStyle();
                //Exit area creation mode
                window.appMap.setMode(window.appMap.modes[0]);
                //Select the area and refresh toolbar
                window.appMap.mapShape.addClass('selected');
                window.appMap.createMainToolbar();
            }
            //Unset everything
            window.appMap.mapShape=undefined;
            window.appMap.creatingShape=false;
        }
    }
});
